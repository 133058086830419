import { z } from 'zod';
import { NestedProductQuoteResponseSchema } from '../product-quote/NestedQuoteResponseEntity.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';
import { NestedProductTypeResponseSchema } from '../product-type/NestedTypeResponseEntity.ts';
import { PRODUCT_CATEGORY_SCHEMA } from './product-category.ts';
import { NestedOrderProductConfigurationSchema } from './nested-product-configuration.ts';

export const GetSelectedProductUrlSchema = z.strictObject({
    productId: z.string().uuid(),
});

export const GetSelectedProductResponseSchema = z.strictObject({
    id: z.string(),
    selectedProductGroupId: z.string(),
    positionProductId: z.string().uuid().nullable(),
    templateId: z.string().nullable(),
    index: z.number(),
    // TODO get rid of type as it is already in the productType object
    type: PRODUCT_TYPE_SCHEMA,
    category: PRODUCT_CATEGORY_SCHEMA,
    productType: NestedProductTypeResponseSchema,
    orderProductConfiguration: NestedOrderProductConfigurationSchema.nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    quote: NestedProductQuoteResponseSchema.nullable(),
});

export type SelectedProductResponse = z.infer<typeof GetSelectedProductResponseSchema>;
