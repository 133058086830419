import { PresetSelection } from '@/shared/components/ProductSelection/PresetSelection/PresetSelection';
import { usePositionTemplateGroups } from '@/clients/template/useTemplate.ts';
import { PrefillTemplate } from '@/shared/types/prefill-template-data';
import { useCreatePositionProductGroup } from '@/clients/position/useCreatePositionProductGroup';
import { useTranslation } from 'react-i18next';
import { TEMPLATE_GROUP_TITLE, TEMPLATE_GROUP_TITLE_LABEL } from '@schuettflix/interfaces';
import {
    useDeletePositionProductGroup,
    usePositionProductGroups,
    useUpdatePositionProductGroup,
} from '@/clients/position/usePositionProductGroup';
import { PositionSection } from '@/modules/position/components/PositionWizard.tsx';
import { ProductSelection } from '@/shared/components/ProductSelection/ProductSelection';
import {
    ProductSelectionSummary,
    ProductSelectionSummaryRow,
} from '@/shared/components/ProductSelection/ProductSelectionSummary';
import { ProductGroups, ProductGroupsProps } from '@/shared/components/ProductSelection/ProductGroups';
import { useMarketAwarePosition } from '@/clients/position/useMarketAwarePosition';
import { useCallback, useState } from 'react';
import { Product } from '@/shared/types/prefill-template-data.ts';
import { useIsMutating } from '@tanstack/react-query';
import { useCreatePositionProduct, useDeletePositionProduct } from '@/clients/position/usePositionProduct';
import { PositionCustomRequestProduct } from '@/CustomRequestProductChannel/modules/position/PositionCustomRequestProduct';
import { usePositionQuote } from '@/CustomRequestProductChannel/hooks/usePositionQuote.ts';
import { ProductSelectionSuspenseBoundary } from '@/shared/components/ProductSelection/ProductSelectionSuspenseBoundary';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';

export interface PositionProductSelectionProps extends PositionSection {
    positionId: string;
}

export const PositionProductSelection = ({
    positionId,
    isSectionOpen,
    onEditCurrentSection,
    onMoveToNextSection,
}: PositionProductSelectionProps) => {
    const { t } = useTranslation();

    const templateGroups = usePositionTemplateGroups();
    const { data: positionProductGroups, error, isInitialLoading } = usePositionProductGroups(positionId);
    const { mutateAsync: mutateDeletePositionProductGroup } = useDeletePositionProductGroup(positionId);
    const { mutateAsync: mutateDeletePositionProduct } = useDeletePositionProduct(positionId);
    const { mutateAsync: mutateCreatePositionProduct } = useCreatePositionProduct(positionId);
    const createPositionProductGroupMutation = useCreatePositionProductGroup(positionId);
    const { data: position } = useMarketAwarePosition(positionId);
    const { isEditing } = useEditPositionState();

    const handleSelectTemplate = async (template: PrefillTemplate) => {
        for (const [index, productGroup] of template.productGroups.entries()) {
            const selectedProductsArray = productGroup.selectedProducts.map((selectedProduct, index) => {
                return {
                    index: index + 1,
                    category: selectedProduct.category,
                    type: selectedProduct.type,
                };
            });

            await createPositionProductGroupMutation.mutateAsync({
                productGroup: {
                    name: t(TEMPLATE_GROUP_TITLE_LABEL[productGroup.name as TEMPLATE_GROUP_TITLE], ''),
                    type: template.type,
                    index: index,
                    templateId: template.templateId,
                    products: selectedProductsArray,
                },
            });
        }
    };

    if (error) {
        return <div className="text-red-800">{error.message}</div>;
    }

    const selectedProductGroups: ProductGroupsProps['selectedProductGroups'] = positionProductGroups
        ? positionProductGroups.map(group => ({
              ...group,
              selectedProducts: group.products?.map(product => ({
                  ...product,
                  selectedProductGroupId: product.positionProductGroupId,
                  templateId: product.templateId,
                  positionProductId: null,
                  productConfiguration: product.positionProductConfiguration,
              })),
          }))
        : [];

    const addProductToProductGroup = async (product: Product, productGroupId: string) => {
        const selectedProducts = selectedProductGroups[0].selectedProducts;
        const highestExistingIndex = selectedProducts?.map(product => product.index).sort((a, b) => b - a)[0] ?? 0;
        await mutateCreatePositionProduct({
            productGroupId,
            product: {
                type: product.type,
                index: highestExistingIndex + 1,
                category: product.category,
            },
        });
    };

    const { mutate: mutateUpdatePositionProductGroup } = useUpdatePositionProductGroup(positionId);
    const handleProductGroupNameChange = useCallback(
        (updatedProductGroupName: string | undefined, productGroupId: string) => {
            mutateUpdatePositionProductGroup({
                productGroupId,
                productGroup: {
                    name: updatedProductGroupName ?? '',
                },
            });
        },
        [mutateUpdatePositionProductGroup]
    );

    const handleDeletePositionProductGroup = async (productGroupId: string) => {
        await mutateDeletePositionProductGroup({ productGroupId });
        onEditCurrentSection();
    };

    const [completedProducts, setCompletedProducts] = useState<Set<string>>(() => new Set([]));

    const disableNextButton = useIsMutating({ mutationKey: ['selected-product-group', 'product-quote'] }) > 0;

    const customRequestProductRenderer = useCallback<ProductGroupsProps['productRender']>(
        props => {
            const handleProductCompleteChange = (productId: string, isComplete: boolean) => {
                setCompletedProducts(prevState => {
                    if (isComplete) {
                        prevState.add(productId);
                    } else {
                        prevState.delete(productId);
                    }
                    return prevState;
                });
            };

            return (
                <PositionCustomRequestProduct
                    {...props}
                    positionId={positionId}
                    onCompleteChange={handleProductCompleteChange}
                />
            );
        },
        [positionId, selectedProductGroups]
    );

    return (
        <ProductSelectionSuspenseBoundary>
            <ProductSelection
                isLoading={isInitialLoading}
                isOpen={isSectionOpen}
                hasProductGroups={!!positionProductGroups?.length}
                summarySlot={
                    <ProductSelectionSummary
                        selectedProductGroups={selectedProductGroups}
                        onEdit={onEditCurrentSection}
                        onDelete={handleDeletePositionProductGroup}
                        disableDelete={isEditing}
                        rowRenderer={({ productId }) => {
                            const { data: positionQuote } = usePositionQuote(productId);
                            return <ProductSelectionSummaryRow quote={constructProductQuote({ positionQuote })} />;
                        }}
                    />
                }
                presetSelectionSlot={
                    <PresetSelection
                        templateGroups={templateGroups?.data}
                        onSelect={handleSelectTemplate}
                        context="POSITION"
                        toMonolithRedirectionUrl={`/order-management/projects/${position?.customerInfo?.constructionProjectId}`}
                    />
                }
                productGroupsSlot={
                    position?.customerInfo ? (
                        <ProductGroups
                            onNextSection={onMoveToNextSection}
                            selectedProductGroups={selectedProductGroups}
                            onDeleteProductGroupById={productGroupId =>
                                mutateDeletePositionProductGroup({ productGroupId })
                            }
                            onDeleteProductById={(productId, productGroupId) =>
                                mutateDeletePositionProduct({ productId, productGroupId })
                            }
                            onAddProductToProductGroup={addProductToProductGroup}
                            onProductGroupNameChange={handleProductGroupNameChange}
                            completedProducts={completedProducts}
                            disableNextButton={disableNextButton}
                            nextButtonLabel={t('product.productTemplates.toPositionSettingsBtn')}
                            isNextButtonSecondary={isEditing}
                            productRender={customRequestProductRenderer}
                            context="POSITION"
                            disableDeleteAllProducts={isEditing}
                            toMonolithRedirectionUrl={`/order-management/projects/${position?.customerInfo?.constructionProjectId}`}
                        />
                    ) : null
                }
            />
        </ProductSelectionSuspenseBoundary>
    );
};
