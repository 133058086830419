import { Suspense } from 'react';
import { InfoCard, LoadingSpinner, Select } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { SelectOrganization } from '@/shared/components/SelectOrganization.tsx';
import { useOrganizationFactories } from '@/clients/factories/useFactories.ts';
import { EditFactoryProductList } from '@/modules/supplierProductManagement/EditFactoryProductList.tsx';
import { useSearchParams } from 'react-router-dom';

export function SupplierManagementPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const organizationIdSearchParam = searchParams.get('organizationId');
    const selectedOrganizationId = organizationIdSearchParam ? parseInt(organizationIdSearchParam) : null;
    const { t } = useTranslation();

    const factoryIdSearchParam = searchParams.get('factoryId');
    const selectedFactoryId = factoryIdSearchParam ? parseInt(factoryIdSearchParam) : null;
    const { data: factoriesData } = useOrganizationFactories(selectedOrganizationId);
    const selectedFactory = factoriesData?.items.find(factory => factory.id === selectedFactoryId);

    return (
        <Suspense
            fallback={
                <div className="flex h-screen items-center justify-center">
                    <LoadingSpinner />
                </div>
            }
        >
            <div>
                <div className="px-4 py-2">
                    <SelectOrganization
                        label={t('sections.clientInformation.selectOrganization.label')}
                        selectedOrganizationId={selectedOrganizationId}
                        value={selectedOrganizationId}
                        onChange={organizationId => {
                            if (organizationId !== null) {
                                setSearchParams(prev => {
                                    prev.set('organizationId', organizationId.toString());
                                    return prev;
                                });
                            }
                        }}
                        filters={{
                            isActive: true,
                            status: ['approved'],
                            organizationType: 'supplier',
                        }}
                    />
                </div>
                {factoriesData ? (
                    <div className="px-4 py-2">
                        <Select
                            value={selectedFactoryId}
                            onChange={factory => {
                                if (factory !== null) {
                                    setSearchParams(prev => {
                                        prev.set('factoryId', factory.toString());
                                        return prev;
                                    });
                                }
                            }}
                            options={
                                factoriesData?.items.map(factory => ({
                                    label: factory.name,
                                    value: factory.id,
                                })) ?? []
                            }
                            label="Factory"
                        />
                    </div>
                ) : null}
                <main className="flex flex-col gap-8 p-4">
                    {selectedFactory ? (
                        <InfoCard title={selectedFactory.name}>
                            <EditFactoryProductList factoryId={selectedFactory.id} />
                        </InfoCard>
                    ) : null}
                    {factoriesData?.items.length === 0 ? <div className="p-4">No Factories</div> : null}
                </main>
            </div>
        </Suspense>
    );
}
