import React, { useState } from 'react';
import {
    FulfillmentProductConfiguration,
    PRODUCT_CATEGORIES,
    PRODUCT_CATEGORY,
    PRODUCT_GROUP_TYPE,
    PRODUCT_GROUP_TYPES,
} from '@schuettflix/interfaces';
import { useTranslation } from 'react-i18next';
import { useOrganizationSuspenseQuery } from '@/clients/organization/useOrganization.ts';
import {
    FulfillmentProduct,
    getProductSectionName,
    PRODUCT_SECTION_PREFIX,
    useFulfillmentContext,
} from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { FulfillmentCustomRequestProduct } from '@/CustomRequestProductChannel/modules/fulfillment/FulfillmentCustomRequestProduct.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFulfillmentQuote } from '@/CustomRequestProductChannel/clients/CustomRequestClient.ts';
import { useSetSectionsValidity } from '@/modules/fulfillment/hooks/useSectionsValidity.ts';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { useFranco } from '@/modules/fulfillment/hooks/useFranco.ts';
import { FulfillmentCustomRequestProductForOrderedProduct } from '@/CustomRequestProductChannel/modules/fulfillment/FulfillmentCustomRequestProductForOrderedProduct.tsx';
import { AddProductButton } from '@/shared/components/AddProductButton/AddProductButton.tsx';
import { ProductGroupHeader } from '@/shared/components/ProductGroupHeader/ProductGroupHeader.tsx';
import {
    deleteFulfillmentProduct,
    patchFulfillmentProductConfiguration,
    postFulfillmentProduct,
} from '@/clients/fulfillment';
import { getFulfillmentProductGroupsByOrderIdQueryKey } from '@/clients/fulfillment/useFulfillmentProductGroup.ts';

interface ProductSelectionSectionProps {
    disabled?: boolean;
}

export const ProductSelectionSection: React.FC<ProductSelectionSectionProps> = ({ disabled }) => {
    const { order, productGroup, products, setCanSubmit, invalidSections } = useFulfillmentContext();
    const { data: orderingOrganization } = useOrganizationSuspenseQuery(order?.customerInfo?.organizationId);

    const originalOrderProducts = products.filter(item => item.inOriginalOrder);
    const nonOriginalOrderProducts = products.filter(item => !item.inOriginalOrder);

    const isFrancoCase = productGroup.type == PRODUCT_GROUP_TYPES.FRANCO;
    const isMerchantCase = productGroup.type == PRODUCT_GROUP_TYPES.MERCHANT;

    const { t } = useTranslation();
    const [deleteModal, setDeleteModal] = useState<
        { open: false } | { open: true; quoteId?: string | null; index?: number | null; productId?: string | null }
    >({ open: false });
    const queryClient = useQueryClient();
    const setSectionsValidity = useSetSectionsValidity();

    const { mutateAsync: mutateAsyncDeleteFulfillmentProduct, isPending: deleteFulfillmentProductIsPending } =
        useMutation({
            mutationKey: ['delete-fulfillment-product'],
            mutationFn: deleteFulfillmentProduct,
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: getFulfillmentProductGroupsByOrderIdQueryKey(productGroup.orderId),
                });
                setDeleteModal({ open: false });
            },
        });

    const { mutateAsync: mutateAsyncDeleteFulfillmentQuote, isPending: deleteFulfillmentQuoteIsPending } = useMutation({
        mutationKey: ['selected-product-group', 'product-quote'],
        mutationFn: deleteFulfillmentQuote,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['quotes-by-order', productGroup.orderId] });
            void queryClient.invalidateQueries({
                queryKey: ['fulfillment-summary', productGroup.orderId],
            });
            setDeleteModal({ open: false });
        },
    });

    const { mutateAsync: mutateAsyncPostFulfillmentProduct } = useMutation({
        mutationKey: ['post-fulfilment-product'],
        mutationFn: postFulfillmentProduct,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: getFulfillmentProductGroupsByOrderIdQueryKey(productGroup.orderId),
            });
        },
    });

    const { mutateAsync: mutateAsyncPatchFulfillmentProductConfiguration } = useMutation({
        mutationKey: ['patch-fulfilment-product-configuration'],
        mutationFn: patchFulfillmentProductConfiguration,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: getFulfillmentProductGroupsByOrderIdQueryKey(productGroup.orderId),
            });
        },
    });

    const getFrancoProductPerCategory = (category: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        return originalOrderProducts.find(product => product.category === category);
    };

    const francoMaterialQuote = getFrancoProductPerCategory(PRODUCT_CATEGORIES.MATERIAL)?.quote;
    const francoTransportQuote = getFrancoProductPerCategory(PRODUCT_CATEGORIES.TRANSPORT)?.quote;

    const { francoFormErrors, onSalesPriceChange } = useFranco({
        initialMaterialSalesPrice: francoMaterialQuote?.salesPrice.toNumber(),
        initialTransportSalesPrice: francoTransportQuote?.salesPrice.toNumber(),
        initialFrancoFormErrors: {},
    });

    const francoMaterialProductFormErrors = {
        ...(francoFormErrors.materialSalesPrice && { salesPrice: francoFormErrors.materialSalesPrice }),
    };

    const francoTransportProductFormErrors = {
        ...(francoFormErrors.transportSalesPrice && { salesPrice: francoFormErrors.transportSalesPrice }),
    };

    if (!products || !orderingOrganization) {
        return null;
    }

    const getHintByProduct = (product: FulfillmentProduct) => {
        if (isFrancoCase) {
            return productGroup.templateId !== product.templateId
                ? t('product.fulfillment.productSelection.productIsNotFrancoHint')
                : t('product.fulfillment.productSelection.productIsFrancoHint');
        }

        if (isMerchantCase && productGroup.templateId !== product.templateId) {
            return t('product.fulfillment.productSelection.productIsNotFrancoHint');
        }
    };

    const getFrancoOverrideFormErrors = (productCategory: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        if (productCategory === PRODUCT_CATEGORIES.MATERIAL) {
            return francoMaterialProductFormErrors;
        }

        if (productCategory === PRODUCT_CATEGORIES.TRANSPORT) {
            return francoTransportProductFormErrors;
        }
    };

    const handleFrancoSalesPriceChange = (salesPrice: number, productCategory: PRODUCT_CATEGORY) => {
        if (!isFrancoCase) {
            return;
        }

        if (productCategory === PRODUCT_CATEGORIES.MATERIAL) {
            onSalesPriceChange(salesPrice, 'materialSalesPrice');
        }

        if (productCategory === PRODUCT_CATEGORIES.TRANSPORT) {
            onSalesPriceChange(salesPrice, 'transportSalesPrice');
        }
    };

    const handleUpdateFulfillmentProductConfiguration = async (
        fulfillmentProductId: string,
        fulfillmentProductConfiguration: Partial<Omit<FulfillmentProductConfiguration, 'fulfillmentProductId'>> | null
    ) => {
        if (!fulfillmentProductConfiguration) return;

        await mutateAsyncPatchFulfillmentProductConfiguration({
            fulfillmentProductId,
            fulfillmentProductConfiguration,
        });
    };

    const handleDeleteProduct = async (productId: string) => {
        await mutateAsyncDeleteFulfillmentProduct({
            fulfillmentProductId: productId,
        });

        const productsInvalidSections = invalidSections.filter(
            section => !section.includes(getProductSectionName(productId)) && section.includes(PRODUCT_SECTION_PREFIX)
        );

        setCanSubmit(productsInvalidSections.length === 0);
    };

    return (
        <div className="rounded bg-white [counter-reset:productCounter]">
            <div className="border-b p-4">
                <Header
                    name={productGroup.name ?? ''}
                    templateId={productGroup.templateId}
                    groupType={productGroup.type}
                />
            </div>
            <div className="divide-y">
                {originalOrderProducts.map(product => (
                    <FulfillmentCustomRequestProductForOrderedProduct
                        key={product.id}
                        product={product}
                        orderingOrganization={orderingOrganization}
                        hint={getHintByProduct(product)}
                        groupType={productGroup.type}
                        disabled={disabled}
                        overrideFormErrors={getFrancoOverrideFormErrors(product.category)}
                        positionProductId={product.positionProductId}
                        onSalesPriceChange={salesPrice => handleFrancoSalesPriceChange(salesPrice, product.category)}
                        productConfiguration={product.fulfillmentProductConfiguration}
                        onUpdateProductConfiguration={productConfiguration =>
                            handleUpdateFulfillmentProductConfiguration(product.id, productConfiguration)
                        }
                    />
                ))}

                {nonOriginalOrderProducts.map(product => (
                    <FulfillmentCustomRequestProduct
                        key={product.id}
                        product={{
                            ...product,
                            selectedProductGroupId: productGroup.id,
                            positionProductId: null,
                            index: 0,
                            productConfiguration: product.fulfillmentProductConfiguration,
                        }}
                        quote={
                            product.quote && {
                                ...product.quote,
                                purchasePrice: product.quote.purchasePrice.toNumber(),
                                salesPrice: product.quote.salesPrice.toNumber(),
                            }
                        }
                        templateId={productGroup.templateId}
                        orderId={productGroup.orderId}
                        showValidation={true}
                        enableDelete={!disabled}
                        disabled={disabled}
                        showProductPriceNotAddedHint={productGroup.type === PRODUCT_GROUP_TYPES.FRANCO}
                        onCompleteChange={(productId, value) => {
                            setSectionsValidity(getProductSectionName(productId), value);

                            void queryClient.invalidateQueries({
                                queryKey: ['payment-terms-fulfillment', productGroup.orderId],
                            });
                        }}
                        onQuoteChange={() => {
                            void queryClient.invalidateQueries({
                                queryKey: ['quotes-by-order', productGroup.orderId],
                            });
                            void queryClient.invalidateQueries({
                                queryKey: ['fulfillment-summary', productGroup.orderId],
                            });
                        }}
                        onDeleteProduct={() => {
                            setDeleteModal({
                                open: true,
                                quoteId: product.quote?.id,
                                productId: product.id,
                            });
                        }}
                        onUpdateProductConfiguration={nonOriginalOrderProductConfiguration =>
                            handleUpdateFulfillmentProductConfiguration(
                                product.id,
                                nonOriginalOrderProductConfiguration
                            )
                        }
                    />
                ))}
            </div>

            {!disabled && (
                <>
                    <AddProductButton
                        templateId={productGroup.templateId}
                        onAddProduct={async product =>
                            await mutateAsyncPostFulfillmentProduct({
                                productCategory: product.category,
                                productType: product.type,
                                productGroupId: productGroup.id,
                                index: products.length + 1,
                            })
                        }
                    />
                    <ModalTrigger
                        isOpen={deleteModal.open}
                        onOpenChange={() => setDeleteModal({ open: !deleteModal.open })}
                    >
                        <Modal>
                            {({ close }) => (
                                <>
                                    <Modal.Heading onAbort={close}>
                                        {t('sections.productGroupSelection.askProductDeletion')}
                                    </Modal.Heading>
                                    <Modal.Content>
                                        {t('sections.productGroupSelection.productDeletionHint')}
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onPress={close} prominence="secondary">
                                            {t('sections.productGroupSelection.cancelProductDeletion')}
                                        </Button>
                                        <Button
                                            isLoading={
                                                deleteFulfillmentProductIsPending || deleteFulfillmentQuoteIsPending
                                            }
                                            onPress={async () => {
                                                if (deleteModal.open && deleteModal.quoteId) {
                                                    await mutateAsyncDeleteFulfillmentQuote({
                                                        quoteId: deleteModal.quoteId,
                                                    });
                                                }

                                                if (deleteModal.open && deleteModal.productId) {
                                                    await handleDeleteProduct(deleteModal.productId);

                                                    setSectionsValidity(
                                                        getProductSectionName(deleteModal.productId),
                                                        true
                                                    );
                                                }

                                                if (deleteModal.open && deleteModal.index !== undefined) {
                                                    setDeleteModal({ open: false });
                                                }
                                            }}
                                        >
                                            {t('sections.productGroupSelection.confirmProductDeletion')}
                                        </Button>
                                    </Modal.Actions>
                                </>
                            )}
                        </Modal>
                    </ModalTrigger>
                </>
            )}
        </div>
    );
};

interface HeaderProps {
    name: string;
    templateId: string;
    groupType: PRODUCT_GROUP_TYPE;
}

const Header: React.FC<HeaderProps> = ({ name, templateId, groupType }) => {
    const { products } = useFulfillmentContext();

    return (
        <ProductGroupHeader
            products={products.map(product => ({
                category: product.category,
                type: product.type,
                templateId: product.templateId ?? null,
                createdAt: new Date(),
                updatedAt: new Date(),
                quote: {
                    templateId: product.templateId,
                    category: product.category,
                    amount: product.quote?.amount,
                    currencyCode: product.quote?.currencyCode,
                    salesPrice: product.quote?.salesPrice.toNumber(),
                    purchasePrice: product.quote?.purchasePrice.toNumber(),
                    unit: product.quote?.unit,
                },
            }))}
            productGroupTemplateId={templateId}
            productGroupType={groupType}
            productGroupName={name}
            productGroupNameDisabled={true}
        />
    );
};
