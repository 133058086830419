import { z } from 'zod';
import { POSITION_PRODUCT_STATUS_SCHEMA, floatingPointPrecisionSchema } from './position-quote-status.ts';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category.ts';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types.ts';

export const GetPositionQuoteUrlSchema = z.object({
    positionProductId: z.string().uuid(),
});

export const GetPositionQuoteResponseSchema = z.strictObject({
    positionId: z.string().uuid(),
    positionProductId: z.string().uuid(),
    name: z.string(),
    status: POSITION_PRODUCT_STATUS_SCHEMA,
    quota: floatingPointPrecisionSchema,
    fulfilledQuantity: floatingPointPrecisionSchema,
    reservedQuantity: floatingPointPrecisionSchema,
    unit: SUPPORTED_UNITS_SCHEMA,
    partnerOrganizationId: z.number().positive(),
    projectOwnerOrganizationId: z.number().positive(),
    platformOrganizationId: z.number().positive(),
    purchasePrice: z.number(),
    purchaseTaxClassId: z.string(),
    salesPrice: z.number(),
    salesTaxClassId: z.string(),
    currencyCode: z.string().max(3),
    productCategory: PRODUCT_CATEGORY_SCHEMA,
    productType: PRODUCT_TYPE_SCHEMA,
    updatedAt: z.coerce.date(),
    createdAt: z.coerce.date(),
});
