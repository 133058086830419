import React, { useState } from 'react';
import { Product } from '@/shared/types/prefill-template-data.ts';
import { GetProductGroupResponseSchema, NestedSelectedProductResponse } from '@schuettflix/interfaces';
import { z } from 'zod';
import {
    ProductGroupHeader,
    ProductGroupHeaderProps,
} from '@/shared/components/ProductGroupHeader/ProductGroupHeader.tsx';
import { AddProductButton } from '@/shared/components/AddProductButton/AddProductButton.tsx';
import { BaseCustomRequestProductProps } from '@/CustomRequestProductChannel/components/CustomRequestProduct';
import { useFranco } from './hooks/useFranco';
import { SimpleDebouncedCustomRequestProductProps } from '@/CustomRequestProductChannel/components/DebouncedCustomRequestProduct';
import { useEditPositionState } from '@/modules/position/hooks/useEditPositionState.ts';

export type ProductGroupProps = {
    productGroup: Omit<z.output<typeof GetProductGroupResponseSchema>, 'orderId' | 'selectedProducts'> & {
        selectedProducts?:
            | (SimpleDebouncedCustomRequestProductProps['product'] &
                  Pick<NestedSelectedProductResponse, 'category' | 'templateId' | 'index'>)[]
            | undefined;
    };
    onAddPosition: (product: Product) => void;
    onDeleteProduct: (productId: string) => void;
    onProductGroupNameChange: (updatedProductGroupName?: string) => void;
    productRender: (props: Omit<SimpleDebouncedCustomRequestProductProps, 'onCompleteChange'>) => React.ReactNode;
    showValidation: boolean;
};

export const ProductGroup: React.FC<ProductGroupProps> = ({
    productGroup,
    onAddPosition,
    onProductGroupNameChange,
    onDeleteProduct,
    productRender: CustomRequestProductElement,
    showValidation,
}) => {
    const { isEditing } = useEditPositionState();
    const { storeProductFormValues, leadingValues, FrancoSchema, allSalesPricesHaveSameSign } = useFranco();
    const [intermediateSelectedProducts, setIntermediateSelectedProducts] = useState<
        ProductGroupHeaderProps['products']
    >([]);

    const updateProductQuote: NonNullable<BaseCustomRequestProductProps['onFormChange']> = ({ values, productId }) => {
        const product = productGroup.selectedProducts?.find(p => p.id === productId);
        if (!product) return;
        setIntermediateSelectedProducts(p => {
            const products = [...p];
            const index = products.findIndex(({ id }) => id === productId);
            const intermediateProduct = products[index];

            const quote = {
                salesPrice: Number(values.salesPrice),
                purchasePrice: Number(values.purchasePrice),
                amount: values.amount,
                currencyCode: values.currencyCode ?? 'EUR',
                unit: values.unit,
            };

            if (intermediateProduct) {
                products[index] = {
                    ...intermediateProduct,
                    quote,
                };
                return products;
            }

            return [...products, { ...product, quote }];
        });
    };

    const handleDeleteProduct = (productId: string) => {
        const productToBeDeleted = productGroup.selectedProducts?.find(p => p.id === productId);

        if (!productToBeDeleted || productToBeDeleted?.templateId) {
            return;
        }

        onDeleteProduct(productId);
        setIntermediateSelectedProducts(previousIntermediateSelectedProducts =>
            previousIntermediateSelectedProducts.filter(p => p.id !== productId)
        );
    };

    function renderProducts() {
        switch (productGroup.type) {
            case 'FRANCO': {
                const materialProduct = productGroup.selectedProducts?.find(product => product.category === 'MATERIAL');
                const transportProduct = productGroup.selectedProducts?.find(
                    product => product.category === 'TRANSPORT'
                );

                if (!materialProduct || !transportProduct) {
                    return null;
                }

                const remainingProducts =
                    productGroup.selectedProducts?.filter(
                        product => product.id !== materialProduct.id && product.id !== transportProduct.id
                    ) ?? [];

                return (
                    <>
                        <CustomRequestProductElement
                            intersectionSchema={FrancoSchema}
                            leadingValues={leadingValues}
                            product={materialProduct}
                            templateId={materialProduct.templateId ?? undefined}
                            onFormChange={updateProductQuote}
                            onBeforeFormChange={values => storeProductFormValues(materialProduct, values)}
                            formFieldSettings={{
                                salesPrice: { isInvalid: !allSalesPricesHaveSameSign },
                            }}
                            showProductPriceAddedHint
                            showValidation={showValidation}
                            disableUnit={isEditing}
                            showZeroPriceProductConfiguration={false}
                        />
                        <CustomRequestProductElement
                            intersectionSchema={FrancoSchema}
                            product={transportProduct}
                            formFieldSettings={{
                                salesPrice: { isInvalid: !allSalesPricesHaveSameSign },
                            }}
                            templateId={transportProduct.templateId ?? undefined}
                            onFormChange={updateProductQuote}
                            onBeforeFormChange={values => storeProductFormValues(transportProduct, values)}
                            leadingValues={leadingValues}
                            showProductPriceAddedHint
                            disableAmount
                            disableUnit
                            showValidation={showValidation}
                            showZeroPriceProductConfiguration={false}
                        />
                        {remainingProducts.map((product, index) => (
                            <CustomRequestProductElement
                                key={index}
                                product={product}
                                templateId={productGroup.templateId}
                                enableDelete
                                showProductPriceNotAddedHint
                                onDeleteProduct={handleDeleteProduct}
                                onFormChange={updateProductQuote}
                                showValidation={showValidation}
                                disableUnit={isEditing}
                            />
                        ))}
                    </>
                );
            }
            case 'STANDARD':
            case 'MERCHANT': {
                return (
                    <>
                        {(productGroup.selectedProducts ?? []).map((product, index) => (
                            <CustomRequestProductElement
                                key={index}
                                product={product}
                                enableDelete={!product.templateId}
                                onDeleteProduct={handleDeleteProduct}
                                templateId={productGroup.templateId}
                                onFormChange={updateProductQuote}
                                showProductPriceNotAddedHint={
                                    (product.category === 'MATERIAL_SERVICE' ||
                                        product.category === 'TRANSPORT_SERVICE') &&
                                    productGroup.type === 'MERCHANT'
                                }
                                showValidation={showValidation}
                                disableUnit={isEditing}
                            />
                        ))}
                    </>
                );
            }
        }
    }

    return (
        <div className="bg-surface mb-12 rounded last:mb-0">
            <div className="border-b p-4">
                <ProductGroupHeader
                    productGroupName={productGroup.name ?? undefined}
                    productGroupType={productGroup.type}
                    productGroupTemplateId={productGroup.templateId}
                    products={intermediateSelectedProducts}
                    onProductGroupNameChange={onProductGroupNameChange}
                />
            </div>
            <div className="flex flex-col divide-y [counter-reset:productCounter]">{renderProducts()}</div>

            <AddProductButton templateId={productGroup.templateId} onAddProduct={onAddPosition} />
        </div>
    );
};
