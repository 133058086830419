import React, { useCallback, useState } from 'react';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { PresetSelection } from '@/shared/components/ProductSelection/PresetSelection/PresetSelection';
import { PrefillTemplate, Product } from '@/shared/types/prefill-template-data.ts';
import { useCreateSelectedProduct, useDeleteSelectedProduct } from '@/clients/selectedProducts/useSelectedProduct.ts';
import {
    useCreateProductGroup,
    useDeleteProductGroup,
    useSelectedProductsGroups,
    useUpdateProductGroup,
} from '@/clients/selectedProducts/useSelectedProductGroup.ts';
import { ProductGroups, ProductGroupsProps } from '@/shared/components/ProductSelection/ProductGroups';
import {
    ProductSelectionSummary,
    ProductSelectionSummaryRow,
} from '@/shared/components/ProductSelection/ProductSelectionSummary';
import { TEMPLATE_GROUP_TITLE, TEMPLATE_GROUP_TITLE_LABEL } from '@schuettflix/interfaces';
import { useOrderTemplateGroups } from '@/clients/template/useTemplate.ts';
import { ProductSelection } from '@/shared/components/ProductSelection/ProductSelection.tsx';
import { OrderCustomRequestProduct } from '@/CustomRequestProductChannel/modules/order/OrderCustomRequestProduct';
import { useProductQuote } from '@/CustomRequestProductChannel/hooks/useProductQuote';
import { useMarketAwareOrder } from '@/clients/order/useOrder.ts';
import { constructProductQuote } from '@/CustomRequestProductChannel/utils/constructProductQuote.ts';
import { useFormattedActiveTaxClassesByOrder } from '@/clients/tax-classes/useTaxClasses.ts';
import { getOrderSummaryQueryKey } from '@/clients/order/useOrderSummary.ts';
import { mapOrderProductGroups } from '@/modules/order/components/ProductSelection/utils/mapOrderProductGroups.ts';

interface OrderProductSelectionProps {
    isOpen: boolean;
    orderId: string;
    onComplete: () => void;
    onEdit: () => void;
    onDelete: () => void;
}

export const OrderProductSelection: React.FC<OrderProductSelectionProps> = ({
    isOpen,
    orderId,
    onComplete,
    onEdit,
    onDelete,
}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { data: order } = useMarketAwareOrder(orderId);
    const taxInfo = useFormattedActiveTaxClassesByOrder(orderId);

    const { selectedProductGroups } = useSelectedProductsGroups(orderId);
    const { createProductGroup } = useCreateProductGroup();
    const { createSelectedProduct } = useCreateSelectedProduct();
    const { deleteProductGroup } = useDeleteProductGroup();
    const templateGroups = useOrderTemplateGroups();

    const onDeleteProductGroup = async (productGroupId: string) => {
        await deleteProductGroup(productGroupId);
        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ['selected-product-group', 'get', orderId] }),
            queryClient.invalidateQueries({ queryKey: getOrderSummaryQueryKey(orderId) }),
        ]);

        if (selectedProductGroups.length === 1) {
            onDelete();
        }
    };

    const handleSelectTemplate = async (template: PrefillTemplate) => {
        for (const [index, templateGroup] of template.productGroups.entries()) {
            const createdProductGroup = await createProductGroup.mutateAsync({
                orderId,
                productGroupName: t(TEMPLATE_GROUP_TITLE_LABEL[templateGroup.name as TEMPLATE_GROUP_TITLE], ''),
                index: index + selectedProductGroups.length,
                templateId: template.templateId,
                type: template.type,
            });
            for (const [index, templateProductPosition] of templateGroup.selectedProducts.entries()) {
                await createSelectedProduct.mutateAsync({
                    type: templateProductPosition.type,
                    productGroupId: createdProductGroup.id,
                    index: index + 1,
                    templateId: template.templateId,
                    category: templateProductPosition.category,
                });
            }
        }

        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ['selected-product-group', 'get', orderId] }),
            queryClient.invalidateQueries({ queryKey: getOrderSummaryQueryKey(orderId) }),
        ]);
    };

    const {
        deleteSelectedProduct: { mutateAsync: deleteProductMutateAsync },
    } = useDeleteSelectedProduct(orderId);

    const handleDeleteProductById = async (productId: string) => {
        await deleteProductMutateAsync({ selectedProductId: productId });
        await queryClient.invalidateQueries({ queryKey: getOrderSummaryQueryKey(orderId) });
    };

    const addProductToProductGroup = async (product: Product, productGroupId: string) => {
        const selectedProducts = selectedProductGroups[0].selectedProducts;
        const highestExistingIndex = selectedProducts?.map(product => product.index).sort((a, b) => b - a)[0] ?? 0;
        await createSelectedProduct.mutateAsync({
            type: product.type,
            productGroupId,
            index: highestExistingIndex + 1,
            category: product.category,
        });
        await queryClient.invalidateQueries({ queryKey: ['selected-product-group', 'get', orderId] });
    };

    const {
        updateProductGroup: { mutate: mutatePatchProductGroup },
    } = useUpdateProductGroup(orderId);

    const handleProductGroupNameChange = useCallback(
        (updatedProductGroupName: string | undefined, productGroupId: string) => {
            mutatePatchProductGroup({
                id: productGroupId,
                orderId: orderId,
                name: updatedProductGroupName,
            });
        },
        [mutatePatchProductGroup]
    );

    const [completedProducts, setCompletedProducts] = useState<Set<string>>(
        () =>
            new Set([
                ...selectedProductGroups.flatMap(
                    group =>
                        group.selectedProducts?.filter(product => product.quote?.id).map(product => product.id) ?? []
                ),
            ])
    );

    const disableNextButton = useIsMutating({ mutationKey: ['selected-product-group', 'product-quote'] }) > 0;
    const handleProductCompleteChange = useCallback((productId: string, isComplete: boolean) => {
        setCompletedProducts(prevState => {
            if (isComplete) {
                prevState.add(productId);
            } else {
                prevState.delete(productId);
            }
            return prevState;
        });
    }, []);

    const customRequestProductRenderer = useCallback<ProductGroupsProps['productRender']>(
        props => {
            return (
                <OrderCustomRequestProduct
                    {...props}
                    orderId={orderId}
                    onCompleteChange={handleProductCompleteChange}
                />
            );
        },
        [orderId, handleProductCompleteChange]
    );

    return (
        <ProductSelection
            isLoading={createSelectedProduct.isPending || createProductGroup.isPending}
            isOpen={isOpen}
            hasProductGroups={!!selectedProductGroups?.length}
            summarySlot={
                <ProductSelectionSummary
                    selectedProductGroups={mapOrderProductGroups(selectedProductGroups)}
                    onEdit={onEdit}
                    onDelete={onDeleteProductGroup}
                    rowRenderer={({ productId }) => {
                        const { data: quote } = useProductQuote(productId);
                        return <ProductSelectionSummaryRow quote={constructProductQuote({ quote, taxInfo })} />;
                    }}
                />
            }
            presetSelectionSlot={
                <PresetSelection
                    templateGroups={templateGroups?.data}
                    onSelect={handleSelectTemplate}
                    context="ORDER"
                />
            }
            productGroupsSlot={
                <ProductGroups
                    onNextSection={() => {
                        onComplete();
                    }}
                    selectedProductGroups={mapOrderProductGroups(selectedProductGroups)}
                    onDeleteProductGroupById={onDeleteProductGroup}
                    onDeleteProductById={handleDeleteProductById}
                    onAddProductToProductGroup={addProductToProductGroup}
                    onProductGroupNameChange={handleProductGroupNameChange}
                    completedProducts={completedProducts}
                    disableNextButton={disableNextButton}
                    disableDeleteAllProducts={!!order?.positionId}
                    nextButtonLabel={t('product.productTemplates.toSummaryBtn')}
                    productRender={customRequestProductRenderer}
                    context="ORDER"
                />
            }
        />
    );
};
