import { ProductGroupProps } from '@/shared/components/ProductSelection/ProductGroup.tsx';
import { GetProductGroupListResponse } from '@schuettflix/interfaces';

export function mapOrderProductGroups(
    selectedProductGroups: GetProductGroupListResponse[]
): ProductGroupProps['productGroup'][] {
    return selectedProductGroups.map(selectedProductGroup => ({
        ...selectedProductGroup,
        selectedProducts: selectedProductGroup.selectedProducts?.map(selectedProduct => ({
            ...selectedProduct,
            productConfiguration: selectedProduct.orderProductConfiguration,
        })),
    }));
}
