import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import {
    GetOrderResponseSchema,
    GetProductGroupListResponseSchema,
    PRODUCT_CATEGORY,
    PRODUCT_TYPES,
    SUPPORTED_UNITS,
    CURRENCY,
    FulfillmentProductConfiguration,
} from '@schuettflix/interfaces';
import { useMarketAwareOrder } from '@/clients/order/useOrder';
import { useSelectedProductGroup } from '@/clients/selectedProducts/useSelectedProductGroup.ts';
import { Price } from '@schuettflix/conversion';
import { useNavigate } from 'react-router-dom';
import { useGetQuotesByOrder } from '@/clients/quotes/useQuotes.ts';
import { useGetFulfillmentProductGroupByOrderId } from '@/clients/fulfillment/useFulfillmentProductGroup.ts';
import { mapToFulfillmentQuote } from '@/modules/fulfillment/utils/mapToFulfillmentQuote.ts';

export const PRODUCT_SECTION_PREFIX = `product-section-item`;

export function getProductSectionName(productId: string) {
    return `${PRODUCT_SECTION_PREFIX}-${productId}`;
}

export type OrderType = z.infer<typeof GetOrderResponseSchema> | null;

export type FulfillmentQuote = {
    id: string;
    name: string;
    selectedProductId: string;
    amount: number;
    unit: SUPPORTED_UNITS;
    currencyCode: CURRENCY;
    partnerOrganizationId: number | null;
    orderingOrganizationId: number;
    platformOrganizationId: number;
    purchasePrice: Price;
    purchaseTaxClassId: string;
    salesPrice: Price;
    salesTaxClassId: string;
    serviceDate: string;
};

export type FulfillmentProduct = {
    id: string;
    category: PRODUCT_CATEGORY;
    type: PRODUCT_TYPES;
    templateId?: string;
    inOriginalOrder: boolean;
    positionProductId: string | null;
    quote?: FulfillmentQuote;
    fulfillmentProductConfiguration: FulfillmentProductConfiguration | null;
};

export type UpdateFulfillmentProduct = Partial<Omit<FulfillmentProduct, 'id'>> & { id: string };

export type FormError = {
    field: string;
    message: string;
    scrollToElement: () => void;

    /** An optional arbitrary id that can help identifying
     * if the error belongs to the current section / component instance
     */
    sectionId?: string;
};

type FulfillmentContextType = {
    order: OrderType;
    setOrder: (value: OrderType) => void;
    products: FulfillmentProduct[];
    documents: DocumentType[];
    setDocuments: (values: DocumentType[]) => void;
    errors: FormError[];
    setErrors: Dispatch<SetStateAction<FormError[]>>;
    updateProduct: (product: UpdateFulfillmentProduct) => void;
    /** Whether the form submit button was clicked */
    isSubmitted: boolean;
    setSubmitted: (value: boolean) => void;
    invalidSections: string[];
    setInvalidSections: Dispatch<SetStateAction<string[]>>;
    isPageValid: boolean;
    canSubmit: boolean;
    setCanSubmit: Dispatch<SetStateAction<boolean>>;
    productGroup: z.input<typeof GetProductGroupListResponseSchema>;
    quotesByOrder: FulfillmentQuote[];
};

type DocumentType = {
    url: string;
    fileName: string;
};

const FulfillmentContext = createContext<FulfillmentContextType | undefined>(undefined);

export const FulfillmentContextProvider: React.FC<{ orderId: string; children: ReactNode }> = ({
    orderId,
    children,
}) => {
    const [order, setOrder] = useState<OrderType>(null);

    const [documents, setDocuments] = useState<DocumentType[]>([]);
    const [errors, setErrors] = useState<FormError[]>([]);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    const [invalidSections, setInvalidSections] = useState<string[]>([]);
    const [isPageValid, setIsPageValid] = useState<boolean>(true);
    const [canSubmit, setCanSubmit] = useState<boolean>(true);

    const { data: orderData } = useMarketAwareOrder(orderId, true);
    const { data: productGroupsData } = useSelectedProductGroup(orderId, true);
    const { data: fulfillmentProductGroup } = useGetFulfillmentProductGroupByOrderId(orderId);
    const { data: quotes } = useGetQuotesByOrder(orderId);

    const quotesByOrder = quotes?.map(mapToFulfillmentQuote) ?? [];
    const [products, setProducts] = useState<FulfillmentProduct[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!quotes || !fulfillmentProductGroup.products) {
            return;
        }
        setProducts(
            fulfillmentProductGroup.products?.map(product => {
                const quote = quotes?.find(quote => quote.selectedProductId === product.id);
                return {
                    id: product.id,
                    category: product.productCategory,
                    type: product.productType,
                    inOriginalOrder: product.source === 'ORDER',
                    positionProductId: null,
                    fulfillmentProductConfiguration: product.fulfillmentProductConfiguration,
                    quote: quote ? mapToFulfillmentQuote(quote) : undefined,
                };
            })
        );
    }, [quotes, fulfillmentProductGroup.products]);

    useEffect(() => {
        if (orderData?.status === 'DRAFT') {
            navigate('/order/' + orderId);
        }
        if (orderData) {
            setOrder(orderData);
        }
    }, [orderData, orderId, navigate]);

    useEffect(() => {
        setIsPageValid(invalidSections.length === 0);
    }, [invalidSections]);

    const updateProduct = (product: UpdateFulfillmentProduct) => {
        setProducts(previousProducts => {
            const productIndex = previousProducts.findIndex(p => p.id === product.id);
            const updatedProducts = [...previousProducts];
            updatedProducts[productIndex] = { ...updatedProducts[productIndex], ...product };

            return updatedProducts;
        });
    };

    return (
        <FulfillmentContext.Provider
            value={{
                order,
                setOrder,
                products,
                quotesByOrder,
                updateProduct,
                documents,
                setDocuments,
                errors,
                setErrors,
                isSubmitted,
                setSubmitted,
                isPageValid,
                invalidSections,
                setInvalidSections,
                productGroup: productGroupsData,
                canSubmit,
                setCanSubmit,
            }}
        >
            {children}
        </FulfillmentContext.Provider>
    );
};

export const useFulfillmentContext = () => {
    const context = useContext(FulfillmentContext);
    if (!context) {
        throw new Error('useFulfillmentContext must be used within a FulfillmentContext');
    }
    return context;
};
