import {
    FulfillmentProduct,
    getProductSectionName,
    useFulfillmentContext,
} from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import {
    PRODUCT_CATEGORY_LABEL,
    PRODUCT_GROUP_TYPE,
    PRODUCT_GROUP_TYPES,
    SUPPORTED_UNIT_LABEL,
    SUPPORTED_UNITS_SCHEMA,
    CURRENCY_DECIMALS,
} from '@schuettflix/interfaces';
import { Organization } from '@/clients/organization/types/organization.ts';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatters } from '@/shared/hooks/useFormatters/useFormatters.ts';
import { useActiveTaxClasses } from '@/clients/tax-classes/useTaxClasses.ts';
import { useOrganizationName } from '@/clients/organization/useOrganization.ts';
import { z } from 'zod';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import { format, isValid as isValidDate, parseISO } from 'date-fns';
import { Price } from '@schuettflix/conversion';
import { useFulfillmentSectionsErrors } from '@/modules/fulfillment/hooks/useFulfillmentSectionsErrors.ts';
import { useSectionsValidity } from '@/modules/fulfillment/hooks/useSectionsValidity.ts';
import { Combobox, TextField } from '@schuettflix/react-components';
import { DatePicker, NumberField } from '@schuettflix/planum-react';
import { parseDate } from '@internationalized/date';
import { Controller, FieldErrors } from 'react-hook-form';
import { ReactHookFormDevTool } from '@/shared/components/ReactHookFormDevTool.tsx';
import { enrichHookFormErrorForFulfillmentSectionErrors } from '@/CustomRequestProductChannel/utils/enrichHookFormErrorForFulfillmentSectionErrors.ts';
import { useSupportedUnitNamesByProductType } from '@/CustomRequestProductChannel/hooks/useSupportedUnitNamesByProductType.ts';
import { getProductCategoryIcon } from '@/CustomRequestProductChannel/utils/getProductCategoryIcon.ts';
import { useFeatureFlag } from '@/tools/featureFlags/useFeatureFlag.ts';
import { validateNegativePrice } from '@/CustomRequestProductChannel/utils/validateNegativePrice.ts';
import {
    CustomRequestProductConfiguration,
    FormErrors,
} from '@/CustomRequestProductChannel/components/CustomRequestProduct.tsx';
import { cn } from '@/shared/utils/cn.ts';
import { CustomRequestProductHints } from '@/CustomRequestProductChannel/components/CustomRequestProductHints.tsx';

interface ProductItemProps {
    product: FulfillmentProduct;
    orderingOrganization: Organization;
    hint?: string;
    groupType?: PRODUCT_GROUP_TYPE;
    disabled?: boolean;
    positionProductId: string | null;
    onPurchasePriceChange?: (purchasePrice: number) => void;
    onSalesPriceChange?: (salesPrice: number) => void;
    overrideFormErrors?: Omit<FormErrors, 'productName' | 'currencyCode'>;
    productConfiguration: CustomRequestProductConfiguration;
    onUpdateProductConfiguration?: (values: Partial<CustomRequestProductConfiguration>) => void;
}

// fixme: this is a special implementation of the FulfillmentCustomRequestProduct component that can show the product from an order above the inputs
// fixme: align with FulfillmentCustomRequestProduct (combine) and use everywhere
export const FulfillmentCustomRequestProductForOrderedProduct: React.FC<ProductItemProps> = ({
    product,
    orderingOrganization,
    hint,
    groupType,
    disabled,
    onPurchasePriceChange,
    onSalesPriceChange,
    overrideFormErrors,
    productConfiguration,
    onUpdateProductConfiguration,
}) => {
    const enableNegativePricesFeatureFlag = useFeatureFlag<boolean>('enableNegativePrices');

    const { products, quotesByOrder, isSubmitted, canSubmit, setCanSubmit, updateProduct } = useFulfillmentContext();
    const { t } = useTranslation();
    const { getCurrencySymbol } = useFormatters();
    const { data: supportedUnitNames } = useSupportedUnitNamesByProductType(product.type);
    const { data: taxClasses } = useActiveTaxClasses(orderingOrganization.market.code);

    const [currentFormErrors, setCurrentFormErrors] = useState<FormErrors>({});
    const formHasErrors = Object.keys(currentFormErrors).length > 0;
    const orderQuote = quotesByOrder.find(quote => quote.selectedProductId === product.id);

    const supportedUnitOptions = (supportedUnitNames ?? []).map(unit => {
        return {
            value: unit,
            label: t(SUPPORTED_UNIT_LABEL[unit]),
        };
    });

    const getFormError = (fieldName: keyof ProductItem) => {
        return currentFormErrors[fieldName];
    };

    const { data: partnerOrganizationName } = useOrganizationName(orderQuote?.partnerOrganizationId);

    const disallowNegativePriceSwitchingRuleProps = (immutableNumValue: number): [RegExp, string] =>
        immutableNumValue < 0
            ? [/^-.*/, t('product.fulfillment.errorMessages.switchToPositivePriceUnsupported')]
            : [/^(?!-).*/, t('product.fulfillment.errorMessages.switchToNegativePriceUnsupported')];

    const ProductItemSchema = z.object({
        name: z.string().min(1, t('product.fulfillment.errorMessages.name.required')),
        amount: z
            .number({ invalid_type_error: t('product.fulfillment.errorMessages.amount.required') })
            .min(0, t('product.fulfillment.errorMessages.amount.minValue')),
        unit: SUPPORTED_UNITS_SCHEMA,
        partnerOrganizationId: z.number().int().positive(),
        platformOrganizationId: z.number().int().positive(),
        purchasePrice: z
            .string()
            .min(1, t('product.fulfillment.errorMessages.purchasePrice.required'))
            .regex(...disallowNegativePriceSwitchingRuleProps(orderQuote!.purchasePrice.toNumber()))
            .refine(
                validateNegativePrice(enableNegativePricesFeatureFlag),
                t('product.addPosition.errorMessage.negativePrices')
            ),
        purchaseTaxClassId: z.string(),
        salesPrice: z
            .string()
            .min(1, t('product.fulfillment.errorMessages.salesPrice.required'))
            .regex(...disallowNegativePriceSwitchingRuleProps(orderQuote!.salesPrice.toNumber()))
            .refine(
                validateNegativePrice(enableNegativePricesFeatureFlag),
                t('product.addPosition.errorMessage.negativePrices')
            ),
        salesTaxClassId: z.string(),
        serviceDate: z
            .string()
            .refine(date => isValidDate(parseISO(date)), t('product.fulfillment.errorMessages.serviceDate.invalid'))
            .refine(
                value => new Date(value) <= new Date(),
                t('product.fulfillment.errorMessages.serviceDate.backdating')
            ),
    });

    type ProductItem = z.infer<typeof ProductItemSchema>;
    type FormErrors = FieldErrors<ProductItem>;

    const {
        register,
        getValues,
        control,
        setValue,
        trigger: triggerFormValidation,
        formState: { errors, isValid },
        watch,
        setFocus,
    } = useZodForm({
        schema: ProductItemSchema,
        defaultValues: {
            name: product.quote?.name,
            amount: product.quote?.amount,
            unit: product.quote?.unit,
            partnerOrganizationId: product.quote?.partnerOrganizationId ?? undefined,
            purchasePrice: product.quote?.purchasePrice.toString(),
            purchaseTaxClassId: product.quote?.purchaseTaxClassId,
            salesPrice: product.quote?.salesPrice.toString(),
            salesTaxClassId: product.quote?.salesTaxClassId,
            serviceDate: product.quote?.serviceDate
                ? format(new Date(product.quote?.serviceDate), 'yyyy-MM-dd')
                : undefined,
            platformOrganizationId: product.quote?.platformOrganizationId,
        },
        mode: 'onChange',
    });

    const handleChange = () => {
        const values = getValues();

        if (!product.quote || !isValid) return;

        updateProduct({
            id: product.id,
            quote: {
                id: product.quote.id,
                selectedProductId: product.id,
                orderingOrganizationId: product.quote.orderingOrganizationId,
                currencyCode: product.quote.currencyCode,
                platformOrganizationId: product.quote.platformOrganizationId,
                purchaseTaxClassId: values.purchaseTaxClassId,
                salesTaxClassId: values.salesTaxClassId,
                purchasePrice: new Price(values.purchasePrice, product.quote.currencyCode),
                amount: values.amount,
                unit: values.unit,
                partnerOrganizationId: values.partnerOrganizationId,
                salesPrice: new Price(values.salesPrice, product.quote.currencyCode),
                serviceDate: values.serviceDate,
                name: values.name,
            },
        });
    };

    const mapPriceToNumber = (value: string) => {
        return new Price(value, orderingOrganization?.market.currencyCode ?? 'EUR').toNumber();
    };

    useEffect(() => {
        const { unsubscribe } = watch(() => {
            handleChange();
        });

        return () => {
            unsubscribe();
        };
    }, [watch, handleChange]);

    const materialQuote = products.find(quote => quote.category === 'MATERIAL')?.quote;

    useEffect(() => {
        if (!isSubmitted) {
            return;
        }

        void triggerFormValidation();
    }, [isSubmitted]);

    useEffect(() => {
        if (groupType !== PRODUCT_GROUP_TYPES.FRANCO || product.category !== 'TRANSPORT') {
            return;
        }
        // bind the amount and unit of the material product to the transport product

        setValue('amount', materialQuote?.amount || 0, { shouldValidate: true });

        if (materialQuote?.unit) {
            setValue('unit', materialQuote.unit, { shouldValidate: true });
        }
        handleChange();
    }, [product.category, groupType, materialQuote?.amount, materialQuote?.unit, setValue]);

    useEffect(() => {
        onPurchasePriceChange?.(mapPriceToNumber(getValues('purchasePrice')));
    }, [getValues('purchasePrice')]);

    useEffect(() => {
        onSalesPriceChange?.(mapPriceToNumber(getValues('salesPrice')));
    }, [getValues('salesPrice')]);

    useEffect(() => {
        if (formHasErrors && canSubmit) {
            setCanSubmit(false);
        }

        if (!formHasErrors && !canSubmit) {
            setCanSubmit(true);
        }
    }, [formHasErrors]);

    useEffect(() => {
        setCurrentFormErrors({
            ...errors,
            ...overrideFormErrors,
        });
    }, [
        errors.salesPrice,
        errors.purchasePrice,
        errors.amount,
        errors.unit,
        errors.purchaseTaxClassId,
        errors.salesTaxClassId,
        errors.serviceDate,
        overrideFormErrors,
    ]);

    useFulfillmentSectionsErrors(enrichHookFormErrorForFulfillmentSectionErrors({ errors, setFocus }));

    useSectionsValidity(getProductSectionName(product.id), isValid);

    if (!orderQuote || !taxClasses) {
        return;
    }

    const currencyPerUnitOrdered = `${getCurrencySymbol(orderingOrganization.market.currencyCode)} / ${t(
        SUPPORTED_UNIT_LABEL[orderQuote.unit]
    )}`;

    const currencyPerUnit = `${getCurrencySymbol(orderingOrganization.market.currencyCode)} / ${t(
        SUPPORTED_UNIT_LABEL[getValues('unit')]
    )}`;

    const formattedTaxClasses = taxClasses.map(taxClass => {
        return {
            value: taxClass.name,
            label: `${taxClass.rate}%`,
            description: taxClass.rate === 0 ? `${taxClass.name} ${taxClass.description}` : '',
        };
    });

    const showZeroPriceProductConfiguration = !(groupType === 'FRANCO' && !product.category.includes('SERVICE'));

    return (
        <form className="focus-within:bg-hovered px-4 py-8">
            <p className="font-copy-sm-strong col-start-2 uppercase [counter-increment:productCounter] before:content-[counter(productCounter)'.\0020']">
                {t(PRODUCT_CATEGORY_LABEL[product.category])}
            </p>
            <div className="mt-4 grid grid-cols-[repeat(5,minmax(0,1fr))] gap-4">
                <div className="col-span-2 truncate">
                    <TextField
                        variant="sm"
                        {...register('name')}
                        errorMessage={getFormError('name')?.message}
                        leadingIcon={getProductCategoryIcon(product.category)}
                        disabled={disabled}
                    />
                </div>
                <div className="row-start-2 grid grid-cols-5 gap-0">
                    <NumberField
                        isDisabled
                        className="col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none"
                        stature="sm"
                        value={orderQuote.amount}
                        formatOptions={{
                            maximumFractionDigits: 3,
                            minimumFractionDigits: 0,
                        }}
                    />
                    <Combobox
                        className="min-w-[95px]"
                        disabled
                        variant="sm"
                        options={supportedUnitOptions}
                        value={orderQuote.unit as string}
                        onChange={() => {}}
                        buttonClassName="rounded-l-none border-l-1"
                    />
                </div>
                <div className="row-start-2 grid grid-cols-1 gap-0">
                    <TextField disabled variant="sm" value={partnerOrganizationName ?? ''} onChange={() => {}} />
                </div>
                <div className="row-start-2 grid grid-cols-5 gap-0">
                    <NumberField
                        isDisabled
                        className="col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none"
                        stature="sm"
                        value={orderQuote.purchasePrice.toNumber()}
                        placeholder={t('product.quote.purchasePrice')}
                        aria-label={t('product.quote.purchasePrice')}
                        trailingSlot={<NumberField.Suffix>{currencyPerUnitOrdered}</NumberField.Suffix>}
                        formatOptions={{
                            maximumFractionDigits: CURRENCY_DECIMALS[orderingOrganization.market.currencyCode],
                            minimumFractionDigits: CURRENCY_DECIMALS[orderingOrganization.market.currencyCode],
                        }}
                    />
                    <Combobox
                        disabled
                        className="col-span-2"
                        variant="sm"
                        value={orderQuote.purchaseTaxClassId}
                        onChange={() => {}}
                        options={formattedTaxClasses}
                        buttonClassName="rounded-l-none border-l-1"
                    />
                </div>
                <div className="row-start-2 grid grid-cols-5 gap-0">
                    <NumberField
                        isDisabled
                        className="col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none"
                        stature="sm"
                        placeholder={t('product.quote.salesPrice')}
                        aria-label={t('product.quote.salesPrice')}
                        value={orderQuote.salesPrice.toNumber()}
                        trailingSlot={<NumberField.Suffix>{currencyPerUnitOrdered}</NumberField.Suffix>}
                        formatOptions={{
                            maximumFractionDigits: CURRENCY_DECIMALS[orderingOrganization.market.currencyCode],
                            minimumFractionDigits: CURRENCY_DECIMALS[orderingOrganization.market.currencyCode],
                        }}
                    />
                    <Combobox
                        disabled
                        className="col-span-2"
                        variant="sm"
                        value={orderQuote.salesTaxClassId}
                        onChange={() => {}}
                        options={formattedTaxClasses}
                        buttonClassName="rounded-l-none border-l-1"
                    />
                </div>
                <div className="row-start-2 [&_.planum-datePicker-module-trigger]:min-w-full">
                    <DatePicker
                        value={parseDate(format(new Date(orderQuote.serviceDate), 'yyyy-MM-dd'))}
                        label={t('product.quote.serviceDate')}
                        stature="sm"
                        isDisabled
                    />
                </div>

                <div className="row-start-3 grid grid-cols-5 gap-0">
                    <Controller
                        name="amount"
                        control={control}
                        render={({ field: { onChange, value, ...field } }) => (
                            <NumberField
                                {...field}
                                errorMessage={getFormError('amount')?.message}
                                className={cn(
                                    'relative col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                    { 'z-10': !!getFormError('amount') }
                                )}
                                value={Number(value)}
                                stature="sm"
                                placeholder={t('product.quote.amount')}
                                aria-label={t('product.quote.amount')}
                                onChange={value => {
                                    onChange(Number.isNaN(value) ? '' : Number(value));
                                }}
                                isDisabled={
                                    disabled ||
                                    (groupType === PRODUCT_GROUP_TYPES.FRANCO && product.category === 'TRANSPORT')
                                }
                                isInvalid={!!getFormError('amount')}
                                formatOptions={{
                                    maximumFractionDigits: 3,
                                    minimumFractionDigits: 0,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="unit"
                        control={control}
                        render={({ field: { ref: _, ...field } }) => {
                            return (
                                <Combobox
                                    {...field}
                                    disabled={
                                        disabled ||
                                        (groupType === PRODUCT_GROUP_TYPES.FRANCO && product.category === 'TRANSPORT')
                                    }
                                    options={supportedUnitOptions}
                                    placeholder={t('product.quote.unit')}
                                    className="min-w-[95px]"
                                    variant="sm"
                                    buttonClassName="rounded-l-none"
                                    optionsClassName="min-w-[100px]"
                                />
                            );
                        }}
                    />
                </div>

                <TextField
                    className="row-start-3"
                    disabled
                    variant="sm"
                    value={partnerOrganizationName ?? ''}
                    onChange={() => {}}
                />

                <div className="row-start-3 grid grid-cols-5 gap-0">
                    <Controller
                        name="purchasePrice"
                        control={control}
                        render={({ field: { onChange, value, ...field } }) => (
                            <NumberField
                                {...field}
                                errorMessage={getFormError('purchasePrice')?.message}
                                className={cn(
                                    'relative col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                    { 'z-10': !!getFormError('purchasePrice') }
                                )}
                                value={value.length ? Number(value) : NaN}
                                stature="sm"
                                placeholder={t('product.quote.purchasePrice')}
                                aria-label={t('product.quote.purchasePrice')}
                                trailingSlot={<NumberField.Suffix>{currencyPerUnit}</NumberField.Suffix>}
                                onChange={value => {
                                    onChange(Number.isNaN(value) ? '' : String(value));
                                }}
                                isDisabled={disabled}
                                isInvalid={!!getFormError('purchasePrice')}
                                formatOptions={{
                                    maximumFractionDigits: CURRENCY_DECIMALS[orderingOrganization.market.currencyCode],
                                    minimumFractionDigits: CURRENCY_DECIMALS[orderingOrganization.market.currencyCode],
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="purchaseTaxClassId"
                        control={control}
                        render={({ field: { ref: _, ...field } }) => (
                            <Combobox
                                {...field}
                                className="col-span-2"
                                variant="sm"
                                options={formattedTaxClasses}
                                buttonClassName="rounded-l-none"
                                optionsClassName="min-w-[200px]"
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
                <div className="row-start-3 grid grid-cols-5 gap-0">
                    <Controller
                        name="salesPrice"
                        control={control}
                        render={({ field: { onChange, value, ...field } }) => (
                            <NumberField
                                {...field}
                                className={cn(
                                    'relative col-span-3 -mr-px focus-within:z-10 hover:z-10 [&_.planum-input-module-wrapper]:rounded-r-none',
                                    { 'z-10': !!getFormError('purchasePrice') }
                                )}
                                value={value.length ? Number(value) : NaN}
                                placeholder={t('product.quote.salesPrice')}
                                aria-label={t('product.quote.salesPrice')}
                                stature="sm"
                                trailingSlot={<NumberField.Suffix>{currencyPerUnit}</NumberField.Suffix>}
                                onChange={value => {
                                    onChange(Number.isNaN(value) ? '' : String(value));
                                }}
                                isDisabled={disabled}
                                isInvalid={!!getFormError('salesPrice')}
                                formatOptions={{
                                    maximumFractionDigits: CURRENCY_DECIMALS[orderingOrganization.market.currencyCode],
                                    minimumFractionDigits: CURRENCY_DECIMALS[orderingOrganization.market.currencyCode],
                                }}
                                errorMessage={getFormError('salesPrice')?.message}
                            />
                        )}
                    />
                    <Controller
                        name="salesTaxClassId"
                        control={control}
                        render={({ field: { ref: _, ...field } }) => (
                            <Combobox
                                {...field}
                                className="col-span-2"
                                variant="sm"
                                options={formattedTaxClasses}
                                buttonClassName="rounded-l-none"
                                optionsClassName="min-w-[200px]"
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
                <div className="row-start-3 [&_.planum-datePicker-module-trigger]:min-w-full">
                    <Controller
                        name="serviceDate"
                        control={control}
                        render={({ field: { value } }) => (
                            <DatePicker
                                value={value ? parseDate(value) : undefined}
                                onChange={date => {
                                    setValue('serviceDate', date?.toString(), {
                                        shouldValidate: true,
                                    });
                                }}
                                label={t('product.quote.serviceDate')}
                                stature="sm"
                                isDisabled={disabled}
                            />
                        )}
                    />
                    <span className="text-critical font-copy-sm mt-2">{getFormError('serviceDate')?.message}</span>
                </div>
            </div>
            {getValues().amount === 0 && (
                <p className="font-copy-sm text-critical mt-4">
                    {t('product.fulfillment.productSelection.amountZeroWarning')}
                </p>
            )}
            <CustomRequestProductHints
                isZeroPurchasePrice={Number(getValues('purchasePrice')) === 0}
                isZeroSalesPrice={Number(getValues('salesPrice')) === 0}
                productConfiguration={productConfiguration}
                onUpdateProductConfiguration={onUpdateProductConfiguration}
                orderingOrganizationName={orderingOrganization.name}
                partnerOrganizationName={partnerOrganizationName}
                isNegativePurchasePrice={false}
                isNegativeSalesPrice={false}
                showProductPriceAddedHint={false}
                showProductPriceNotAddedHint={false}
                showZeroPriceProductConfiguration={showZeroPriceProductConfiguration}
            />
            {!!hint && <p className="font-copy-sm text-disabled mt-4">{hint}</p>}
            <ReactHookFormDevTool control={control} />
        </form>
    );
};
