import React, { useCallback, useState } from 'react';
import { DeleteIcon } from '@schuettflix/icons-react';
import { Product } from '@/shared/types/prefill-template-data.ts';
import { useTranslation } from 'react-i18next';
import { ProductGroup, ProductGroupProps } from './ProductGroup.tsx';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { CloseIcon } from '@schuettflix/planum-icons-react';
import { useNavigation } from '@/shared/hooks/useNavigation';
import { SimpleDebouncedCustomRequestProductProps } from '@/CustomRequestProductChannel/components/DebouncedCustomRequestProduct.tsx';

export type ProductGroupsProps = {
    selectedProductGroups: ProductGroupProps['productGroup'][];
    positionSelectedProductGroups?: [];
    onNextSection?: () => void;
    nextButtonLabel: string;
    isNextButtonSecondary?: boolean;
    onDeleteProductGroupById: (productGroupId: string) => Promise<void>;
    onDeleteProductById: (productId: string, productGroupId: string) => Promise<void>;
    onAddProductToProductGroup: (product: Product, productGroupId: string) => void;
    onProductGroupNameChange: (name: string | undefined, productGroupId: string) => void;
    completedProducts: Set<string>;
    disableNextButton: boolean;
    disableDeleteAllProducts?: boolean;
    productRender: (props: Omit<SimpleDebouncedCustomRequestProductProps, 'onCompleteChange'>) => React.ReactNode;
    orderId?: string;
    context: 'ORDER' | 'FULFILLMENT' | 'POSITION';
    toMonolithRedirectionUrl?: string;
};

export const ProductGroups: React.FC<ProductGroupsProps> = ({
    selectedProductGroups,
    onNextSection,
    onDeleteProductGroupById,
    onDeleteProductById,
    onAddProductToProductGroup,
    onProductGroupNameChange,
    completedProducts,
    disableNextButton,
    disableDeleteAllProducts = false,
    nextButtonLabel,
    isNextButtonSecondary = false,
    productRender,
    context,
    toMonolithRedirectionUrl,
}) => {
    const { t } = useTranslation();

    const { isComputingNavigation, setIsComputingNavigation, setShouldNavigate } =
        useNavigation(toMonolithRedirectionUrl);

    const [showValidation, setShowValidation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLoadingDeletion, setIsLoadingDeletion] = useState(false);

    const handleSummaryButtonClick = () => {
        const selectedProductIds: string[] = selectedProductGroups.flatMap(
            group => group.selectedProducts?.map(product => product.id) ?? []
        );

        if (selectedProductIds.every(productId => completedProducts.has(productId))) {
            setShowValidation(false);
            onNextSection?.();
        } else {
            setShowValidation(true);
        }
    };

    const customRequestProductRenderer = useCallback<ProductGroupProps['productRender']>(
        props => productRender({ ...props }),
        []
    );

    const getQuestionTranslationForDiscardingChanges = useCallback(() => {
        switch (context) {
            case 'ORDER':
                return t('sections.productGroupSelection.order.discardChangesQuestion');
            case 'POSITION':
                return t('sections.productGroupSelection.position.discardChangesQuestion');
            case 'FULFILLMENT':
                return t('sections.productGroupSelection.fulfillment.discardChangesQuestion');
            default:
                return t('sections.productGroupSelection.order.discardChangesQuestion');
        }
    }, []);

    // TODO Add hint to submit button that we are not able to save the order if not all products have a quote
    return (
        <div className="flex flex-col items-center">
            <div className="mb-8 mt-12 flex w-full flex-col rounded border-none">
                <div className="text mb-4 flex self-center border-t pt-[40px]">
                    <div className="flex w-[720px] justify-between">
                        <div>
                            <h2 className="font-headline-lg">{t('sections.productGroupSelection.title')}</h2>
                        </div>
                        {!disableDeleteAllProducts && (
                            <ModalTrigger isOpen={isModalOpen} onOpenChange={setIsModalOpen}>
                                <div className="flex items-center justify-between">
                                    <DeleteIcon
                                        size="xs"
                                        monochrome
                                        className="cursor-pointer"
                                        onClick={() => setIsModalOpen(true)}
                                    />
                                    <button className="font-copy-md ml-2 w-max" onClick={() => setIsModalOpen(true)}>
                                        {t('sections.productGroupSelection.deleteAllProducts')}
                                    </button>
                                </div>
                                <Modal>
                                    {({ close }) => (
                                        <>
                                            <Modal.Heading onAbort={close}>
                                                {t('sections.productGroupSelection.askProductDeletion')}
                                            </Modal.Heading>
                                            <Modal.Content>
                                                {t('sections.productGroupSelection.productDeletionHint')}
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button
                                                    onPress={close}
                                                    prominence="secondary"
                                                    leadingSlot={<CloseIcon />}
                                                >
                                                    {t('sections.productGroupSelection.cancelProductDeletion')}
                                                </Button>
                                                <Button
                                                    isLoading={isLoadingDeletion}
                                                    onPress={async () => {
                                                        if (isLoadingDeletion) return;
                                                        setIsLoadingDeletion(true);
                                                        try {
                                                            await onDeleteProductGroupById(selectedProductGroups[0].id);
                                                            close();
                                                        } catch (error) {
                                                            console.error(error);
                                                        }
                                                        setIsLoadingDeletion(false);
                                                    }}
                                                >
                                                    {t('sections.productGroupSelection.confirmProductDeletion')}
                                                </Button>
                                            </Modal.Actions>
                                        </>
                                    )}
                                </Modal>
                            </ModalTrigger>
                        )}
                    </div>
                </div>
                {selectedProductGroups.map(productGroup => (
                    <ProductGroup
                        key={productGroup.id}
                        productGroup={productGroup}
                        onAddPosition={product => onAddProductToProductGroup(product, productGroup.id)}
                        onProductGroupNameChange={name => onProductGroupNameChange(name, productGroup.id)}
                        onDeleteProduct={productId => onDeleteProductById(productId, productGroup.id)}
                        productRender={customRequestProductRenderer}
                        showValidation={showValidation}
                    />
                ))}
            </div>
            <footer className="mb-8 flex max-w-[720px] items-center justify-between">
                <Button
                    onPress={() => handleSummaryButtonClick()}
                    isDisabled={disableNextButton}
                    data-test="product-selection-button"
                    className="flex-1 whitespace-nowrap"
                    stature="md"
                    prominence={isNextButtonSecondary ? 'secondary' : 'primary'}
                >
                    {nextButtonLabel}
                </Button>
            </footer>
            <div className="border-t-divider w-full max-w-[720px] border-t">&nbsp;</div>
            {isComputingNavigation && (
                <ModalTrigger isOpen={isComputingNavigation} onOpenChange={setIsComputingNavigation}>
                    <Modal>
                        {({ close }) => (
                            <>
                                <Modal.Heading onAbort={close}>
                                    {getQuestionTranslationForDiscardingChanges()}
                                </Modal.Heading>
                                <Modal.Content>{t('hints.discardChanges')}</Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        onPress={() => {
                                            setIsComputingNavigation(false);
                                            setShouldNavigate(false);
                                            close();
                                        }}
                                        prominence="secondary"
                                    >
                                        {t('sections.productGroupSelection.cancelProductDeletion')}
                                    </Button>
                                    <Button
                                        onPress={() => {
                                            setIsComputingNavigation(false);
                                            setShouldNavigate(true);
                                            close();
                                        }}
                                    >
                                        {t('page.buttons.yesCancel')}
                                    </Button>
                                </Modal.Actions>
                            </>
                        )}
                    </Modal>
                </ModalTrigger>
            )}
        </div>
    );
};
