import React, { useEffect } from 'react';
import { useFormErrorMessages } from '@/shared/hooks/useFormErrorMessages.ts';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useConstructionProject } from '@/clients/constructionProject/useConstructionProject.ts';
import { useUpdateOrder } from '@/clients/order/useUpdateOrder.ts';
import { useOrganizationSuspenseQuery } from '@/clients/organization/useOrganization.ts';
import { SelectConstructionProject } from '@/modules/order/components/SelectConstructionProject.tsx';
import { SelectOrganization } from '@/shared/components/SelectOrganization.tsx';
import { SelectUser } from '@/shared/components/SelectUser.tsx';
import { MarketSelector } from '@/modules/order/components/MarketSelector.tsx';
import { PostCustomerInfoRequestSchema } from '@schuettflix/interfaces';
import { Button as Button, TextField } from '@schuettflix/react-components';
import { Controller, useForm } from 'react-hook-form';
import { Button as PlanumButton, Modal, ModalTrigger } from '@schuettflix/planum-react';
import { ReactHookFormDevTool } from '@/shared/components/ReactHookFormDevTool.tsx';
import { useMarketAwareOrder } from '@/clients/order/useOrder.ts';
import { useMarketAwarePosition } from '@/clients/position/useMarketAwarePosition.ts';
import { ClientInformationSummaryContent } from '@/shared/components/ClientInformationSummaryContent.tsx';
import { SectionSummary } from '@/shared/components/SectionSummary.tsx';
import { useNavigation } from '@/shared/hooks/useNavigation';

export interface ClientInformationFormValues {
    organizationId: z.output<typeof PostCustomerInfoRequestSchema>['organizationId'] | null;
    userId: z.output<typeof PostCustomerInfoRequestSchema>['userId'] | null;
    manualCostCenter: z.output<typeof PostCustomerInfoRequestSchema>['manualCostCenter'];
    constructionProjectId: z.output<typeof PostCustomerInfoRequestSchema>['constructionProjectId'] | null;
    projectCode: z.output<typeof PostCustomerInfoRequestSchema>['projectCode'];
}

export interface ClientInformationFormOrderProps {
    orderId: string;
    isOpen: boolean;
    onComplete: () => void;
    onEdit: () => void;
}

export const ClientInformationFormOrder: React.FC<ClientInformationFormOrderProps> = ({
    isOpen,
    orderId,
    onComplete,
    onEdit,
}) => {
    const { t } = useTranslation();
    const { isComputingNavigation, setIsComputingNavigation, setShouldNavigate } = useNavigation();
    const { data: order } = useMarketAwareOrder(orderId);
    const { data: position } = useMarketAwarePosition(order?.positionId);
    const { data: defaultConstructionProject } = useConstructionProject(
        position?.customerInfo.constructionProjectId ?? order?.customerInfo?.constructionProjectId
    );

    const currentConstructionProjectId =
        position?.customerInfo.constructionProjectId ?? order?.customerInfo?.constructionProjectId;
    const currentOrganizationId = position?.customerInfo.organizationId ?? order?.customerInfo?.organizationId;
    const currentUserId = position?.customerInfo.userId ?? order?.customerInfo?.userId;

    const defaultValues = {
        organizationId: currentOrganizationId ?? null,
        userId: currentUserId ?? null,
        manualCostCenter: position?.customerInfo.manualCostCenter ?? order?.customerInfo?.manualCostCenter ?? '',
        constructionProjectId: currentConstructionProjectId ?? null,
        projectCode: defaultConstructionProject?.projectCode ?? '',
    };

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        clearErrors,
        getValues,
    } = useForm<ClientInformationFormValues>({
        defaultValues: defaultValues,
    });

    const { getErrorMessage } = useFormErrorMessages(errors);
    const { updateOrderWithClientInfo, updateOrderWithUpdatedClientInfo } = useUpdateOrder(orderId);

    const organizationId = watch('organizationId');
    const constructionProjectId = watch('constructionProjectId');
    const userId = watch('userId');
    const customerInfoId = order?.customerInfo?.id;

    const { data: constructionProject } = useConstructionProject(constructionProjectId);

    const projectCode = constructionProject?.projectCode;
    const { data: organization } = useOrganizationSuspenseQuery(organizationId);

    useEffect(() => {
        if (constructionProject?.costCenter) {
            setValue('manualCostCenter', constructionProject?.costCenter);
        }
    }, [constructionProject?.costCenter, setValue]);

    if (!isOpen) {
        return (
            <SectionSummary title={`1. ${t('sections.clientInformation.title')}`} editable onEditClick={onEdit}>
                <ClientInformationSummaryContent
                    organizationId={organization?.id}
                    userId={currentUserId}
                    constructionProjectId={constructionProject?.id}
                    manualCostCenter={constructionProject?.costCenter}
                />
            </SectionSummary>
        );
    }

    const isOrganizationDisabled = !!position?.customerInfo.organizationId;
    const isConstructionProjectDisable = !!position?.customerInfo.constructionProjectId || !organizationId;

    return (
        <>
            <form
                className="mb-6 grid w-full max-w-[720px] gap-12 border-b pb-[40px]"
                onSubmit={handleSubmit(() => {
                    const getClientInformationFormValues = getValues();

                    if (!organizationId || !userId || !constructionProjectId || !projectCode) {
                        return;
                    }

                    if (!customerInfoId) {
                        updateOrderWithClientInfo.mutate({
                            organizationId: organizationId,
                            userId: userId,
                            manualCostCenter: getClientInformationFormValues.manualCostCenter,
                            constructionProjectId: constructionProjectId,
                            projectCode: projectCode,
                        });
                    } else {
                        updateOrderWithUpdatedClientInfo.mutate({
                            organizationId: organizationId,
                            userId: userId,
                            manualCostCenter: getClientInformationFormValues.manualCostCenter,
                            constructionProjectId: constructionProjectId,
                            id: customerInfoId,
                            projectCode: projectCode,
                        });
                    }

                    onComplete();
                })}
            >
                <h2 className="text font-headline-lg">1. {t('sections.clientInformation.title')}</h2>

                <MarketSelector disabled={!!order?.positionId} />

                <fieldset>
                    <h3 className="font-copy-md-strong mb-2">{t('sections.clientInformation.description')}</h3>
                    <div className="grid gap-2">
                        <Controller
                            name="organizationId"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                                <SelectOrganization
                                    {...field}
                                    label={t('sections.clientInformation.selectOrganization.label')}
                                    critical={!!getErrorMessage('organizationId')}
                                    helperText={getErrorMessage('organizationId') as string}
                                    selectedOrganizationId={organization?.id}
                                    disabled={isOrganizationDisabled}
                                    onChange={organizationId => {
                                        if (!organizationId || organizationId != organization?.id) {
                                            setValue('manualCostCenter', null);
                                            setValue('constructionProjectId', null);
                                            setValue('userId', null);
                                        }

                                        clearErrors('constructionProjectId');
                                        onChange(organizationId || null);
                                    }}
                                    filters={{
                                        isActive: true,
                                        isBlocked: false,
                                        status: ['approved'],
                                        organizationType: 'client',
                                    }}
                                    dataTest="organization-input"
                                />
                            )}
                        />
                        <Controller
                            name="constructionProjectId"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                                <SelectConstructionProject
                                    {...field}
                                    criticalized={!!getErrorMessage('constructionProjectId', !organizationId)}
                                    helperText={getErrorMessage('constructionProjectId') as string}
                                    organizationId={organizationId}
                                    disabled={isConstructionProjectDisable}
                                    onChange={value => {
                                        setValue('manualCostCenter', null);
                                        onChange(value);
                                    }}
                                    filters={{
                                        statuses: ['ACTIVE', 'INACTIVE'],
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="userId"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                                <SelectUser
                                    {...field}
                                    label={t('sections.clientInformation.selectUser.label')}
                                    criticalized={!!getErrorMessage('userId')}
                                    helperText={getErrorMessage('userId') as string}
                                    disabled={!organizationId}
                                    onChange={value => {
                                        onChange(value?.id ?? null);
                                    }}
                                    filters={{
                                        isActive: true,
                                        organization: organizationId,
                                    }}
                                />
                            )}
                        />

                        <Controller
                            name="manualCostCenter"
                            control={control}
                            rules={{
                                required: organization?.costcenterMandatory ?? false,
                            }}
                            render={({ field: { value, ...field } }) => (
                                <TextField
                                    {...field}
                                    value={value ?? ''}
                                    errorMessage={getErrorMessage('manualCostCenter') as string}
                                    label={t('sections.clientInformation.manualCostCenterLabel')}
                                    disabled={!organizationId}
                                />
                            )}
                        />
                    </div>
                </fieldset>

                <footer className="flex justify-center">
                    <Button
                        data-test="product-selection-button"
                        className="self-center"
                        label={t('sections.clientInformation.nextButton')}
                        size="sm"
                    ></Button>
                </footer>

                <ReactHookFormDevTool control={control} />
            </form>
            {isComputingNavigation && (
                <ModalTrigger isOpen={isComputingNavigation} onOpenChange={setIsComputingNavigation}>
                    <Modal>
                        {({ close }) => (
                            <>
                                <Modal.Heading onAbort={close}>
                                    {t('sections.productGroupSelection.order.discardChangesQuestion')}
                                </Modal.Heading>
                                <Modal.Content>{t('hints.discardChanges')}</Modal.Content>
                                <Modal.Actions>
                                    <PlanumButton
                                        onPress={() => {
                                            setIsComputingNavigation(false);
                                            setShouldNavigate(false);
                                            close();
                                        }}
                                        prominence="secondary"
                                    >
                                        {t('sections.productGroupSelection.cancelProductDeletion')}
                                    </PlanumButton>
                                    <PlanumButton
                                        onPress={() => {
                                            setIsComputingNavigation(false);
                                            setShouldNavigate(true);
                                            close();
                                        }}
                                    >
                                        {t('page.buttons.yesCancel')}
                                    </PlanumButton>
                                </Modal.Actions>
                            </>
                        )}
                    </Modal>
                </ModalTrigger>
            )}
        </>
    );
};
