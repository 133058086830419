import { useCallback } from 'react';
import { CombinedProductsCard } from './components/CombinedProductsCard.tsx';
import { ProductCard } from './components/ProductCard.tsx';
import { useTranslation } from 'react-i18next';
import { PrefillTemplate } from '@/shared/types/prefill-template-data';
import { TemplateGroup } from '@schuettflix/interfaces';
import { groupTemplateGroupsBySection } from '@/shared/components/ProductSelection/PresetSelection/utils/groupTemplateGroupsBySection.ts';
import { useNavigation } from '@/shared/hooks/useNavigation';
import { Button, Modal, ModalTrigger } from '@schuettflix/planum-react';
interface PresetSelectionProps {
    onSelect: (template: PrefillTemplate) => void;
    templateGroups?: TemplateGroup[];
    context: 'ORDER' | 'FULFILLMENT' | 'POSITION';
    toMonolithRedirectionUrl?: string;
}

export const PresetSelection = ({
    onSelect,
    templateGroups,
    context,
    toMonolithRedirectionUrl,
}: PresetSelectionProps) => {
    const { t } = useTranslation();

    const { isComputingNavigation, setIsComputingNavigation, setShouldNavigate } =
        useNavigation(toMonolithRedirectionUrl);

    const templateGroupsBySection = groupTemplateGroupsBySection(templateGroups ?? []);

    const getQuestionTranslationForDiscardingChanges = useCallback(() => {
        switch (context) {
            case 'ORDER':
                return t('sections.productGroupSelection.order.discardChangesQuestion');
            case 'POSITION':
                return t('sections.productGroupSelection.position.discardChangesQuestion');
            case 'FULFILLMENT':
                return t('sections.productGroupSelection.fulfillment.discardChangesQuestion');
            default:
                return t('sections.productGroupSelection.order.discardChangesQuestion');
        }
    }, []);

    return (
        <section className="my-6 flex flex-col border-y py-[40px]">
            <h2 className="font-headline-lg">{t('sections.productGroupSelection.title')}</h2>
            <div className="mt-6 flex w-full flex-col gap-8">
                {(['TRANSPORT_MATERIAL', 'TRANSPORT', 'MATERIAL', 'SERVICE'] as const).map(type => {
                    return (
                        <div key={type} className="flex flex-col">
                            <div className="pb-2">
                                <span className="text font-copy-xs-strong uppercase">
                                    {
                                        {
                                            TRANSPORT_MATERIAL: `${t('products.richCatalogue.materialHeader')} + ${t('products.richCatalogue.transportHeader')}`,
                                            TRANSPORT: t('products.richCatalogue.transportHeader'),
                                            MATERIAL: t('products.richCatalogue.materialHeader'),
                                            SERVICE: t('products.richCatalogue.serviceHeader'),
                                        }[type]
                                    }
                                </span>
                            </div>
                            {type === 'TRANSPORT_MATERIAL' ? (
                                <div className="grid gap-2">
                                    {(templateGroupsBySection.TRANSPORT_MATERIAL ?? []).map(templateGroup => (
                                        <CombinedProductsCard
                                            key={templateGroup.id}
                                            templateGroup={templateGroup}
                                            onSelect={onSelect}
                                            dataTest={`combineProductCard-${templateGroup.name}`}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="grid grid-cols-2 gap-2">
                                    {(templateGroupsBySection[type] ?? []).map(templateGroup => (
                                        <ProductCard
                                            key={templateGroup.id}
                                            templateGroup={templateGroup}
                                            onSelect={onSelect}
                                            context={type.toLowerCase() as Lowercase<typeof type>}
                                            dataTest={`productCard-${templateGroup.name}`}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {isComputingNavigation && (
                <ModalTrigger isOpen={isComputingNavigation} onOpenChange={setIsComputingNavigation}>
                    <Modal>
                        {({ close }) => (
                            <>
                                <Modal.Heading onAbort={close}>
                                    {getQuestionTranslationForDiscardingChanges()}
                                </Modal.Heading>
                                <Modal.Content>{t('hints.discardChanges')}</Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        onPress={() => {
                                            setIsComputingNavigation(false);
                                            setShouldNavigate(false);
                                            close();
                                        }}
                                        prominence="secondary"
                                    >
                                        {t('sections.productGroupSelection.cancelProductDeletion')}
                                    </Button>
                                    <Button
                                        onPress={() => {
                                            setIsComputingNavigation(false);
                                            setShouldNavigate(true);
                                            close();
                                        }}
                                    >
                                        {t('page.buttons.yesCancel')}
                                    </Button>
                                </Modal.Actions>
                            </>
                        )}
                    </Modal>
                </ModalTrigger>
            )}

            <div className="border-t-divider mb-4 mt-[40px] w-full max-w-[720px] border-t">&nbsp;</div>
        </section>
    );
};
