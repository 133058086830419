import React from 'react';
import {
    BaseCustomRequestProductProps,
    CustomRequestProduct,
    CustomRequestProductForm,
} from '@/CustomRequestProductChannel/components/CustomRequestProduct.tsx';
import { useMutation } from '@tanstack/react-query';
import {
    patchFulfillmentQuote,
    postFulfillmentQuote,
} from '@/CustomRequestProductChannel/clients/CustomRequestClient.ts';
import { Price } from '@schuettflix/conversion';
import { CustomRequestSuspenseBoundary } from '@/CustomRequestProductChannel/components/CustomRequestSuspenseBoundary.tsx';
import { usePlatformOrganizationId } from '@/shared/hooks/usePlatformOrganizationId';
import { useMarketAwareOrder } from '@/clients/order/useOrder';
import { NestedSelectedProductResponse } from '@schuettflix/interfaces';
import { useFormattedActiveTaxClassesByOrder } from '@/clients/tax-classes/useTaxClasses';
import { useProductQuote } from '@/CustomRequestProductChannel/hooks/useProductQuote';
import { useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext.tsx';

export type FulfillmentCustomRequestProductManagerProps = Omit<
    BaseCustomRequestProductProps,
    'customerId' | 'taxInfo'
> & {
    product: BaseCustomRequestProductProps['product'] & Pick<NestedSelectedProductResponse, 'selectedProductGroupId'>;
    orderId: string;
    onQuoteChange?: () => void;
    onCompleteChange: (productId: string, value: boolean) => void; // shared
};

export const FulfillmentCustomRequestProductManager: React.FC<FulfillmentCustomRequestProductManagerProps> = props => {
    const { data: order } = useMarketAwareOrder(props.orderId);
    const { data: quote } = useProductQuote(props.product.id);
    const { setCanSubmit } = useFulfillmentContext();

    const orderingOrganizationId = order?.customerInfo?.organizationId;
    const platformOrganizationId = usePlatformOrganizationId();

    const { mutateAsync: mutateAsyncPostFulfillmentQuote, isPending: postFulfillmentQuoteIsPending } = useMutation({
        mutationKey: ['selected-product-group', 'product-quote'],
        mutationFn: postFulfillmentQuote,
        onSuccess: () => {
            props.onQuoteChange?.();
        },
    });

    const { mutateAsync: mutateAsyncPatchFulfillmentQuote, isPending: patchFulfillmentQuoteIsPending } = useMutation({
        mutationKey: ['selected-product-group', 'product-quote'],
        mutationFn: patchFulfillmentQuote,
        onSuccess: () => {
            props.onQuoteChange?.();
        },
    });

    const taxInfo = useFormattedActiveTaxClassesByOrder(props.orderId);

    if (!platformOrganizationId || !orderingOrganizationId) return null;

    const handleSubmit = async (formValues: CustomRequestProductForm) => {
        if (patchFulfillmentQuoteIsPending || postFulfillmentQuoteIsPending) {
            return;
        }
        try {
            if (quote?.meta.id) {
                await mutateAsyncPatchFulfillmentQuote({
                    body: {
                        meta: {
                            quote: {
                                orderingOrganizationId,
                                purchaseTaxClassId: formValues.purchaseTaxClassId,
                                salesTaxClassId: formValues.salesTaxClassId,
                                purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                                amount: formValues.amount,
                                unit: formValues.unit,
                                partnerOrganizationId: formValues.partnerOrganizationId,
                                salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                                serviceDate: formValues.serviceDate,
                            },
                            customerInformation: {
                                customerOrganizationId: orderingOrganizationId,
                            },
                            selectedProduct: {
                                productCategory: props.product.category,
                            },
                        },
                        payload: {
                            name: formValues.productName,
                        },
                    },
                    productId: props.product.id,
                });
            } else {
                await mutateAsyncPostFulfillmentQuote({
                    meta: {
                        quote: {
                            orderId: props.orderId,
                            orderingOrganizationId,
                            purchaseTaxClassId: formValues.purchaseTaxClassId,
                            salesTaxClassId: formValues.salesTaxClassId,
                            purchasePrice: new Price(formValues.purchasePrice, formValues.currencyCode).toNumber(),
                            amount: formValues.amount,
                            unit: formValues.unit,
                            partnerOrganizationId: formValues.partnerOrganizationId,
                            platformOrganizationId,
                            currencyCode: formValues.currencyCode,
                            salesPrice: new Price(formValues.salesPrice, formValues.currencyCode).toNumber(),
                            serviceDate: new Date(formValues.serviceDate),
                        },
                        customerInformation: {
                            customerOrganizationId: orderingOrganizationId,
                        },
                        selectedProduct: {
                            selectedProductId: props.product.id,
                            productCategory: props.product.category,
                            productType: props.product.type,
                            selectedProductGroupId: props.product.selectedProductGroupId,
                            index: props.product.index,
                        },
                    },
                    payload: {
                        name: formValues.productName,
                    },
                });
            }

            props.onCompleteChange(props.product.id, true);
        } catch (err) {
            console.error('handleSubmit: ', err);
            props.onCompleteChange(props.product.id, false);
        }

        props.onCompleteChange(props.product.id, true);
    };

    return (
        <CustomRequestSuspenseBoundary>
            <CustomRequestProduct
                {...props}
                customerId={orderingOrganizationId}
                taxInfo={taxInfo}
                onFormChange={(...params) => {
                    const [form] = params;
                    if (form.isValid) {
                        void handleSubmit(form.values);
                        setCanSubmit(true);
                    } else {
                        // if we have errors, this section is not valid. By this callback we trigger uproot that this section is not valid.
                        props?.onCompleteChange(props.product.id, false);
                        setCanSubmit(false);
                    }
                    props.onFormChange?.(...params);
                }}
                restrictFutureServiceDate
            />
        </CustomRequestSuspenseBoundary>
    );
};
