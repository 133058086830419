import React, { useMemo } from 'react';

import { useFulfillmentContext } from '@/modules/fulfillment/context/FulfillmentContext.tsx';
import { useBalanceSheet } from '@/shared/hooks/useBalanceSheet.ts';
import { FinanceSettings } from '@/shared/components/FinanceSettings/FinanceSettings.tsx';
import { SummaryItems } from '@/shared/components/SummaryItems.tsx';
import { useTranslation } from 'react-i18next';
import { useSectionsValidity } from '../hooks/useSectionsValidity.ts';
import { useFulfillmentSectionsErrors } from '../hooks/useFulfillmentSectionsErrors.ts';
import { useFulfillmentSummary } from '@/clients/order/useFulfillmentSummary.ts';
import { GetFulfillmentSummaryPayload } from '@/clients/order/order.ts';

interface SummaryProps {
    orderId: string;
    disabled?: boolean;
}

export const Summary: React.FC<SummaryProps> = ({ orderId, disabled }) => {
    const { order, isSubmitted, products: fulfillmentProducts } = useFulfillmentContext();

    // quotes that were on the original order are not directly synced with the now fulfilled values to the backend,
    // to enable the backend to calculate the new values for the summary, we need to send the updated product values
    const products = useMemo<GetFulfillmentSummaryPayload['products']>(
        () =>
            fulfillmentProducts
                .filter(({ inOriginalOrder, quote }) => inOriginalOrder && quote !== undefined)
                .map(({ id, quote }) => {
                    return {
                        productId: id,
                        purchasePrice: quote!.purchasePrice.toNumber(),
                        salesPrice: quote!.salesPrice.toNumber(),
                        quantity: quote!.amount,
                        productName: quote!.name,
                        unit: quote!.unit,
                        orderingOrganizationId: quote!.orderingOrganizationId,
                    };
                }),
        [fulfillmentProducts]
    );

    const { data: fulfillmentSummary } = useFulfillmentSummary(orderId, { products });
    const { t } = useTranslation();

    const balanceSheetMap = useBalanceSheet(fulfillmentSummary?.organizations?.flatMap(({ totals }) => totals) || []);

    const errors = useMemo(
        () =>
            fulfillmentSummary?.organizations?.flatMap(org => {
                return org.financeSettings.flatMap(({ selected, transactionalRole }) => {
                    const field = `paymentTerm-${org.orgId}-${transactionalRole}`;
                    return selected.billingMethod !== 'PARTNER_INVOICE' && selected.paymentTermId === null
                        ? [
                              {
                                  field,
                                  message: t('errorMessages.form.required'),
                                  scrollToElement: () => {
                                      document
                                          .querySelector(`[name="${field}"]`)
                                          ?.scrollIntoView({ behavior: 'smooth' });
                                  },
                              },
                          ]
                        : [];
                });
            }) || [],
        [fulfillmentSummary]
    );

    useSectionsValidity('summary', !errors.length);
    useFulfillmentSectionsErrors(errors);

    if (!order || !fulfillmentSummary) {
        return;
    }

    return (
        <>
            <div className="mb-2 flex flex-col gap-6">
                <FinanceSettings
                    referenceId={orderId}
                    groups={fulfillmentSummary.organizations}
                    disabled={disabled}
                    showErrorMessages={isSubmitted}
                    context="FULFILLMENT"
                />
                <SummaryItems balanceSheetMap={balanceSheetMap} />
            </div>
        </>
    );
};
