import { useEffect, useState } from 'react';
import { useMonolithFrontendUrl } from '@/shared/hooks/useMonolithFrontendUrl.ts';
import { useNavigate, useLocation } from 'react-router-dom';

export const useNavigation = (path?: string) => {
    const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
    const [isComputingNavigation, setIsComputingNavigation] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    const { openUrl: openOverviewPage } = useMonolithFrontendUrl({
        target: 'currentTab',
    });

    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            event.preventDefault();
            setIsComputingNavigation(true);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        if (shouldNavigate) {
            const pathName = window.location.pathname;
            let redirectTo: string | undefined;

            if (pathName.includes('order')) {
                redirectTo = '/dashboard';
            }
            if (pathName.includes('fulfillment')) {
                redirectTo = '/order-management/orders';
            }
            if (pathName.includes('position')) {
                redirectTo = '/order-management/projects';
            }

            openOverviewPage({ path: path || redirectTo });
        } else {
            navigate(location.pathname);
        }
    }, [shouldNavigate]);

    return {
        isComputingNavigation,
        setIsComputingNavigation,
        setShouldNavigate,
    };
};
