import {
    CURRENCY_SCHEMA,
    PRODUCT_NAME_LABEL,
    SERVICE_PRODUCT_NAME_VALUE,
    SUPPORTED_UNITS,
} from '@schuettflix/interfaces';
import { Price } from '@schuettflix/conversion';
import { format } from 'date-fns';
import { BaseCustomRequestProductProps } from '@/CustomRequestProductChannel/components/CustomRequestProduct.tsx';
import { useOrganization } from '@/clients/organization/useOrganization.ts';
import { useTemplate } from '@/clients/template/useTemplate.ts';
import { useTranslation } from 'react-i18next';
import { CustomRequestProductSchema } from '@/CustomRequestProductChannel/hooks/useCustomRequestProductSchema.ts';
import { useMemo } from 'react';

type UsePresetFormValuesProps = Pick<
    BaseCustomRequestProductProps,
    'leadingValues' | 'formFieldSettings' | 'quote' | 'templateId' | 'customerId' | 'product' | 'taxInfo'
>;

export function usePresetFormValues({
    leadingValues,
    formFieldSettings,
    quote,
    templateId,
    customerId,
    product,
    taxInfo,
}: UsePresetFormValuesProps) {
    const { t } = useTranslation();
    const { data: organization } = useOrganization(customerId);
    const { data: template } = useTemplate(templateId);
    const defaultProductName =
        product.type in SERVICE_PRODUCT_NAME_VALUE ? t(PRODUCT_NAME_LABEL[product.type]) : undefined;

    const defaultValues = {
        amount: leadingValues?.amount ?? quote?.amount,
        unit:
            leadingValues?.unit ??
            quote?.unit ??
            (template?.allowedProducts.find(({ type }) => type === product.type)?.prefilledData?.unit as
                | SUPPORTED_UNITS
                | undefined) ??
            (template?.preSelectedProducts.find(({ type }) => type === product.type)?.prefilledData?.unit as
                | SUPPORTED_UNITS
                | undefined) ??
            undefined,
        partnerOrganizationId: quote?.partnerOrganizationId ?? undefined,
        purchasePrice: quote
            ? new Price(quote.purchasePrice, CURRENCY_SCHEMA.parse(quote.currencyCode)).toString()
            : undefined,
        purchaseTaxClassId: quote?.purchaseTaxClassId ?? taxInfo?.defaultTaxClass,
        salesPrice: quote
            ? new Price(quote.salesPrice, CURRENCY_SCHEMA.parse(quote.currencyCode)).toString()
            : undefined,
        salesTaxClassId: quote?.salesTaxClassId ?? taxInfo?.defaultTaxClass,
        serviceDate: quote?.serviceDate ? format(new Date(quote.serviceDate), 'yyyy-MM-dd') : '',
        productName: quote?.name ?? defaultProductName,
        currencyCode: organization?.market.currencyCode ?? 'EUR',
    };

    const presetValues = useMemo<Partial<CustomRequestProductSchema> | undefined>(() => {
        if (!organization || !template) {
            return;
        }

        return formFieldSettings
            ? {
                  ...defaultValues,
                  ...Object.fromEntries(
                      Object.entries(formFieldSettings)
                          .filter(([_, { isOptional }]) => isOptional)
                          .map(([name]) => {
                              // this allows to redefine via the formFieldSettings the mentioned fields to be optional
                              // these "optional" fields are still required for the schema validation and therefore are directly assigned
                              // with valid default values. This fakes an optional behavior for the mentioned fields.
                              // this works for all fields but during the time of creation this mechanism is was only necessary for the serviceDate field in positions.
                              const defaultValuesToFakeOptionalBehavior: CustomRequestProductSchema = {
                                  amount: 1,
                                  unit: 'TON',
                                  partnerOrganizationId: 1,
                                  purchasePrice: '1',
                                  purchaseTaxClassId: 'S-DE',
                                  salesPrice: '1',
                                  salesTaxClassId: 'S-DE',
                                  serviceDate: '2024-01-01',
                                  productName: 'Undefined',
                                  currencyCode: 'EUR',
                              };
                              return [
                                  name,
                                  defaultValuesToFakeOptionalBehavior[name as keyof CustomRequestProductSchema],
                              ];
                          })
                  ),
              }
            : defaultValues;
    }, [organization, template]);

    return {
        presetValues,
    };
}
