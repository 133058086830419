import { z } from 'zod';
import { PRODUCT_GROUP_TYPE_SCHEMA } from '../product-group/product-group-type';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types';
import { NestedPositionProductSchema } from './get-position-product.ts';

export const PostPositionGroupUrlSchema = z.object({
    positionId: z.string().uuid(),
});

const PostPositionProductSchema = z.object({
    index: z.number().nonnegative(),
    category: PRODUCT_CATEGORY_SCHEMA,
    type: PRODUCT_TYPE_SCHEMA,
});

export const PostPositionGroupRequestSchema = z.object({
    index: z.number().nonnegative(),
    name: z.string(),
    templateId: z.string().uuid(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
    products: z.array(PostPositionProductSchema).optional(),
});

export const PostPositionGroupResponseSchema = z.object({
    id: z.string().uuid(),
    index: z.number().nonnegative(),
    name: z.string(),
    templateId: z.string().uuid(),
    type: PRODUCT_GROUP_TYPE_SCHEMA,
    positionId: z.string().uuid(),
    products: z.array(NestedPositionProductSchema),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullable(),
});
