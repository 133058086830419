import { z } from 'zod';
import { useZodForm } from '@/shared/hooks/useZodForm.ts';
import React, { useId, useState } from 'react';
import { Button, Combobox, TextField } from '@schuettflix/react-components';
import { Controller } from 'react-hook-form';
import { createSellerProduct } from '@/clients/sellerProducts/SellerProduct.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAvailableFactoryProducts } from '@/clients/factories/useFactories.ts';
import { invalidateSellerProductsQuery } from '@/clients/sellerProducts/useSellerProduct.ts';

const CreateForm = z.object({
    productDefinitionId: z.number().nonnegative().nullable(),
    pricePerTon: z.number().positive(),
    stockInTons: z.number().positive(),
    isBlockedOnSpotMarket: z.boolean(),
});

export function CreateFactoryProductForm({ factoryId }: { factoryId: number }) {
    const { data: availableProductDefinitions } = useAvailableFactoryProducts(factoryId);

    const {
        handleSubmit,
        register,
        control,
        formState: { isValid, isDirty, errors },
        reset,
    } = useZodForm({
        schema: CreateForm,
        defaultValues: {
            productDefinitionId: null,
            pricePerTon: 0,
            stockInTons: 0,
            isBlockedOnSpotMarket: false,
        },
        mode: 'onChange',
    });

    const blockedOnSpotMarketId = useId();

    const queryClient = useQueryClient();
    const [formBodyError, setFormBodyError] = useState<string | null>(null);
    const createSellerProductMutation = useMutation({
        mutationKey: ['seller-products', 'post', factoryId],
        mutationFn: createSellerProduct,
        onError: (error: unknown) => {
            const validationError = z
                .object({
                    response: z.object({
                        data: z.array(
                            z.object({
                                property_path: z.string(),
                                message: z.string(),
                            })
                        ),
                    }),
                })
                .safeParse(error);
            if (validationError.success) {
                for (const error of validationError.data.response.data) {
                    switch (error.property_path) {
                        case 'body': {
                            setFormBodyError(error.message);
                            break;
                        }
                    }
                }
            }
        },
        onSuccess: () => {
            setFormBodyError(null);
        },
    });

    return (
        <div>
            <form
                className="flex flex-wrap items-center justify-between gap-1"
                onSubmit={handleSubmit(async formData => {
                    const selectedProductDefinition = availableProductDefinitions?.items?.find(
                        product => product.id === formData.productDefinitionId
                    );
                    if (!selectedProductDefinition) {
                        return;
                    }
                    await createSellerProductMutation.mutateAsync({
                        factoryId,
                        body: {
                            available: true,
                            price: formData.pricePerTon.toString(10),
                            masterProductId: selectedProductDefinition.masterProductId,
                            minimumGuaranteedAmount: formData.stockInTons,
                            ...(selectedProductDefinition.grainSize
                                ? {
                                      grainSize: {
                                          min: selectedProductDefinition.grainSize.min,
                                          max: selectedProductDefinition.grainSize.max,
                                          unit: selectedProductDefinition.grainSize.unit,
                                      },
                                  }
                                : {}),
                            isBlockedOnSpotMarket: formData.isBlockedOnSpotMarket,
                            attributeValues: selectedProductDefinition.attributeValueIds,
                        },
                    });
                    reset();
                    await invalidateSellerProductsQuery(queryClient, factoryId);
                })}
            >
                <Controller
                    name={'productDefinitionId'}
                    control={control}
                    render={({ field }) => (
                        <Combobox
                            className="shrink-0 flex-grow basis-[150px]"
                            value={field.value}
                            onChange={field.onChange}
                            searchFn={(options, searchTerm) => {
                                return options
                                    .filter(option =>
                                        searchTerm.split(/\s+/).every(word => {
                                            return option.label.toLowerCase().includes(word.toLowerCase());
                                        })
                                    )
                                    .sort((a, b) => a.label.length - b.label.length);
                            }}
                            options={
                                availableProductDefinitions?.items?.map(product => ({
                                    value: product.id,
                                    label: product.name,
                                })) ?? []
                            }
                            label="Product"
                            multiple={false}
                        />
                    )}
                />
                <TextField
                    className="shrink-0 flex-grow basis-[150px]"
                    {...register('pricePerTon', { valueAsNumber: true })}
                    label="Price per Ton"
                    errorMessage={errors.pricePerTon?.message}
                    type="number"
                    step={0.01}
                />
                <TextField
                    className="shrink-0 flex-grow basis-[150px]"
                    {...register('stockInTons', { valueAsNumber: true })}
                    label="Stock in Tons"
                    errorMessage={errors.stockInTons?.message}
                    type="number"
                    step={0.01}
                />
                <div>
                    <label htmlFor={blockedOnSpotMarketId}>Only visible for Schüttflix</label>
                    <input id={blockedOnSpotMarketId} type="checkbox" {...register('isBlockedOnSpotMarket')} />
                </div>
                <Button label="Create" type="submit" disabled={!isValid || !isDirty} />
            </form>
            {formBodyError ? <p className="text-critical">{formBodyError}</p> : null}
        </div>
    );
}
