import { z } from 'zod';
import { POSITION_PRODUCT_STATUS_SCHEMA, floatingPointPrecisionSchema } from './position-quote-status.ts';
import { PRODUCT_CATEGORY_SCHEMA } from '../selected-product/product-category';
import { PRODUCT_TYPE_SCHEMA } from '../product-type/product-types';
import { SUPPORTED_UNITS_SCHEMA } from '../quote-unit/unit-types';
import { GetPositionQuoteResponseSchema } from './get-position-quote.ts';

export const PatchPositionQuoteUrlSchema = z.object({
    positionProductId: z.string().uuid(),
});

export const PatchPositionQuoteRequestSchema = z.object({
    status: POSITION_PRODUCT_STATUS_SCHEMA.optional(),
    name: z.string().optional(),
    quota: floatingPointPrecisionSchema.optional(),
    unit: SUPPORTED_UNITS_SCHEMA.optional(),
    partnerOrganizationId: z.number().positive().optional(),
    purchasePrice: z.number().optional(),
    purchaseTaxClassId: z.string().optional(),
    salesPrice: z.number().optional(),
    salesTaxClassId: z.string().optional(),
    currencyCode: z.string().max(3).optional(),
    productCategory: PRODUCT_CATEGORY_SCHEMA.optional(),
    productType: PRODUCT_TYPE_SCHEMA.optional(),
});

export type PatchPositionQuoteRequest = z.infer<typeof PatchPositionQuoteRequestSchema>;

export const PatchPositionQuoteResponseSchema = GetPositionQuoteResponseSchema;

export type PatchPositionQuoteResponse = z.infer<typeof PatchPositionQuoteResponseSchema>;
